import React, {useRef} from 'react';
import 'material-icons/iconfont/material-icons.scss';
import i18next from "../../languages/no/translation";

function GreetingVideo(props: any) {

    function previewVideo(event: any) {
        let videoPreview = document.getElementById('video_preview');
        let videoFile = event.target.files[0];
        let fileReader = new FileReader();
        fileReader.onload = function (fileLoadedEvent) {
            // @ts-ignore
            let srcData = fileLoadedEvent.target.result;
            props.setGreetingContentVideo(srcData);
            // @ts-ignore
            videoPreview.remove();
            let source = document.createElement("source");
            if (typeof srcData === "string") {
                source.src = srcData;
            }
            source.id = "video_preview";
            source.type = "video/mp4";
            let element = document.getElementById("greeting-video-wrapper");
            // @ts-ignore
            element.appendChild(source);
        }
        fileReader.readAsDataURL(videoFile);
        props.setFormChanged(true);
        setTimeout(function () {
            setVideoDuration();
        }, 1000);
    }

    const setVideoDuration = () => {
        let element = document.getElementById("greeting-video-wrapper");
        // @ts-ignore
        props.setGreetingContentVideoDuration(Math.floor(element.duration));
    };

    const inputVideo = useRef(null);
    const onChooseVideoButtonClick = () => {
        // @ts-ignore
        inputVideo.current.click();
    };



    const captureVideoSuccess = function (mediaFiles: string | any[]) {


        let videoPreview = document.getElementById('video_preview');
        //let videoFile = mediaFiles[0].fullPath;
        let videoFile = mediaFiles[0];

        // @ts-ignore
        /*videoPreview.remove();
        let v = "<video controls='controls'>";
        v += "<source src='" + mediaFiles[0].fullPath + "' type='video/mp4'>";
        //v += "<source src='" + mediaFiles[0].localURL + "' type='video/mp4'>";
        v += "</video>";
        let element = document.getElementById("greeting-video-wrapper");
        // @ts-ignore
        element.innerHTML = v;*/

        // @ts-ignore
        videoPreview.remove();
        let source = document.createElement("source");
        source.src = URL.createObjectURL(mediaFiles[0].fullPath);
        //source.src = URL.createObjectURL(mediaFiles[0].localURL);
        source.id = "video_preview";
        source.type = "video/mp4";
        let element = document.getElementById("greeting-video-wrapper");
        // @ts-ignore
        element.appendChild(source);


        /*let fileReader = new FileReader();
        fileReader.onload = function (fileLoadedEvent) {
            // @ts-ignore
            let srcData = fileLoadedEvent.target.result;
            alert(srcData);
            props.setGreetingContentVideo(srcData);
            // @ts-ignore
            videoPreview.remove();
            let source = document.createElement("source");
            if (typeof srcData === "string") {
                source.src = srcData;
            }
            source.id = "video_preview";
            source.type = "video/mp4";
            let element = document.getElementById("greeting-video-wrapper");
            // @ts-ignore
            element.appendChild(source);
        }
        let file = new File(videoFile.localURL,videoFile.name, {type: videoFile.type});
        //let file = new Blob([videoFile], {type: videoFile.type});
        fileReader.readAsDataURL(file);
        props.setFormChanged(true);*/

    };

    const captureVideoError = function (error: { code: string; }) {
        // @ts-ignore
        navigator.notification.alert('Error code: ' + error.code, null, 'Capture Error');
    };

    const onCaptureVideoButtonClick = () => {
        if (process.env.REACT_APP_PLATTFORM != 'web') {
            // @ts-ignore
            navigator.device.capture.captureVideo(captureVideoSuccess, captureVideoError);
        }
    };


    return (
        <div className="row my-3 border border-1 rounded p-3">
            <div className="col-12">
                <input ref={inputVideo} className="d-none" accept="video/mp4,video/x-m4v,video/*" type="file"
                       name="video" id="video"
                       onChange={previewVideo}/>
                <video className="greeting-video-wrapper" id="greeting-video-wrapper">
                    <source id="video_preview" type="video/mp4" src={props.video}/>
                </video>
            </div>
            <div className="col-12">
                <button onClick={onChooseVideoButtonClick} type="button"
                        className="btn btn-primary mt-2">{i18next.t('greeting.registration_form.button_choose_video')}</button>
                {props.deviceReady &&
                <button onClick={onCaptureVideoButtonClick} type="button"
                        className="btn btn-primary mt-2 ms-2">{i18next.t('greeting.registration_form.button_capture_video')}</button>}
            </div>
        </div>
    );
}

export default GreetingVideo;