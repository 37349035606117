import './App.scss';
import React, {useEffect, useState} from 'react';
import {BrowserRouter, Route} from "react-router-dom";
import Header from './components/site/Header';
import Frontpage from './components/site/Frontpage';
import Footer from './components/site/Footer';
import Login from './components/user/Login';
import Logout from './components/user/Logout';
import User from './components/user/User';
import NewPassword from './components/user/NewPassword';
import SetPassword from './components/user/SetPassword';
import GreetingList from './components/greeting/GreetingList';
import Greeting from './components/greeting/Greeting';
import ReceiverList from './components/receiver/ReceiverList';
import Receiver from './components/receiver/Receiver';
import ProtectedRoute from './components/site/ProtectedRoute';
import {selectBackgroundImage} from "./store/slices/styleSlice";
import {useAppSelector} from "./store/hooks";
import Privacy from "./components/article/Privacy";
import About from "./components/article/About";
import GreetingForReceiver from "./components/greeting/GreetingForReceiver";
import PreppForm from "./components/preppform/PreppForm";
import PreppForReceiver from "./components/preppform/PreppForReceiver";

function App() {

    const bootstrap = require('bootstrap');
    const backgroundImage = useAppSelector(selectBackgroundImage);
    const css = {
        backgroundImage: `url(${process.env.PUBLIC_URL + backgroundImage.background_image})`,
        backgroundPosition: 'center top',
        backgroundRepeat: 'space',
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
        boxShadow: 'inset 0 0 0 100vmax rgba(37, 21, 68, 0.6)',
        margin: 0,
        padding: 0,
        minHeight: '100vh'
    };

    return (
        <div className="App text-center d-flex flex-column h-100" id="content" style={css}>
            <BrowserRouter>
                <Route component={Header}/>
                <Route path="/" exact component={Frontpage}/>
                <Route path="/login" component={Login}/>
                <Route path="/register" component={User}/>
                <Route path="/new-password" component={NewPassword}/>
                <Route path="/set-password/:token" component={SetPassword}/>
                <Route path="/privacy" component={Privacy}/>
                <Route path="/about" component={About}/>
                <Route path="/g/:token" component={GreetingForReceiver}/>
                <Route path="/prepp/:token" component={PreppForReceiver}/>
                <ProtectedRoute path="/profile" component={User}/>
                <ProtectedRoute path="/receivers" component={ReceiverList}/>
                <ProtectedRoute path={["/receiver/:receiver_id", "/receiver"]} component={Receiver}/>
                <ProtectedRoute path="/greetings" component={GreetingList}/>
                <ProtectedRoute
                    path={["/greeting/:greeting_id?video=1", "/greeting/:greeting_id", "/greeting?video=1", "/greeting"]}
                    component={Greeting}/>
                <ProtectedRoute path="/prepp-form" component={PreppForm}/>
                <ProtectedRoute path="/logout" component={Logout}/>
                <Route component={Footer}/>
            </BrowserRouter>
        </div>
    );
}

export default App;
