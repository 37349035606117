import i18next from 'i18next';

i18next.init(
    {
        lng: 'no',
        debug: true,
        resources: {
            no: {
                translation: {
                    "menu": {
                        "home": "Hjem",
                        "register": "Registrer",
                        "login": "Logg inn",
                        "logout": "Logg ut",
                        "profile": "Profil",
                        "video": "Mine videoer",
                        "receiver": "Mine mottakere",
                        "greeting": "Mine hilsener",
                        "prepp_form": "Preppskjema"
                    },
                    "user": {
                        "registration_form": {
                            "title": "Registreringsskjema",
                            "fields": {
                                "first_name": {
                                    "label": "Fornavn",
                                    "placeholder": "Ola",
                                },
                                "last_name": {
                                    "label": "Etternavn",
                                    "placeholder": "Normann",
                                },
                                "address": {
                                    "label": "Adresse",
                                    "placeholder": "Dronningsgate",
                                },
                                "zip": {
                                    "label": "Postnr",
                                    "placeholder": "7000",
                                },
                                "city": {
                                    "label": "Poststed",
                                    "placeholder": "Trondheim",
                                },
                                "date_of_birth": {
                                    "label": "Født",
                                    "placeholder": "24/12/2000",
                                },
                                "social_security_number": {
                                    "label": "Personnummer",
                                    "placeholder": "2412200012345",
                                },
                                "country": {
                                    "label": "Land",
                                    "placeholder": "Norge",
                                },
                                "phone_number": {
                                    "label": "Telefon",
                                    "placeholder": "44556677",
                                },
                                "email": {
                                    "label": "Epost",
                                    "placeholder": "ola.normann@epost.no",
                                },
                                "password": {
                                    "label": "Passord",
                                    "placeholder": " ",
                                },
                                "repeat_password": {
                                    "label": "Repeter passord",
                                    "placeholder": " ",
                                },
                                "terms": {
                                    "label": "Jeg godtar betingelser",
                                    "placeholder": " ",
                                }
                            },
                            "user_term_title": "Betingelser",
                            "delete_confirm_message": "Vil du slette din profil og alle hilsener?",
                            "button_register": "Registrer",
                            "button_update": "Oppdater",
                            "button_delete": "Slett profil",
                            "success_message_delete": "Din profil og alle hilsener ble slettet",
                            "success_message_register": "Du ble nå registrert, og vil bli videresendt til innloggingssiden innen 5 sekunder",
                            "success_message_update_profile": "Din profil ble oppdatert",
                            "form_has_changed": "Du har ikke lagret endringene. Er du sikker du vil forlate siden?"
                        },
                        "login_form": {
                            "title": "Registreringsskjema",
                            "fields": {
                                "email": {
                                    "label": "Epost",
                                    "placeholder": "ola.normann@epost.no",
                                },
                                "password": {
                                    "label": "Passord",
                                    "placeholder": " ",
                                }
                            },
                            "button": "Logg inn"
                        },
                        "new_password_form": {
                            "title": "Glemt passord?",
                            "fields": {
                                "email": {
                                    "label": "Epost",
                                    "placeholder": "ola.normann@epost.no",
                                }
                            },
                            "success_message": "Link for å sette nytt passord har blitt sendt til deg",
                            "button": "Send nytt passord"
                        },
                        "set_password_form": {
                            "title": "Lagre nytt passord",
                            "fields": {
                                "password": {
                                    "label": "Passord",
                                    "placeholder": " ",
                                },
                                "repeat_password": {
                                    "label": "Gjenta passord",
                                    "placeholder": " ",
                                }
                            },
                            "success_message": "Passord ble oppdatert, du kan logge inn med ditt nye passord i appen",
                            "button": "Lagre passord"
                        },
                    },
                    "receiver": {
                        "registration_form": {
                            "title": "Registrer ny mottaker",
                            "fields": {
                                "first_name": {
                                    "label": "Fornavn",
                                    "placeholder": "Ola",
                                },
                                "last_name": {
                                    "label": "Etternavn",
                                    "placeholder": "Normann",
                                },
                                "address": {
                                    "label": "Adresse",
                                    "placeholder": "Dronningsgate",
                                },
                                "address_line_2": {
                                    "label": "Adresselinje 2",
                                    "placeholder": " ",
                                },
                                "zip": {
                                    "label": "Postnr",
                                    "placeholder": "7000",
                                },
                                "city": {
                                    "label": "Poststed",
                                    "placeholder": "Trondheim",
                                },
                                "country": {
                                    "label": "Land",
                                    "placeholder": "Norge",
                                },
                                "phone_country_code": {
                                    "label": "Landskode",
                                    "placeholder": " ",
                                },
                                "phone_number": {
                                    "label": "Telefon",
                                    "placeholder": "44556677",
                                },
                                "email": {
                                    "label": "Epost",
                                    "placeholder": "ola.normann@epost.no",
                                },
                                "status": {
                                    "label": "Aktiv"
                                },
                                "minor": {
                                    "label": "Er mottaker under 18 år?"
                                },
                                "date_of_birth": {
                                    "label": "Fødselsdato",
                                    "placeholder": "24/12/2010",
                                }
                            },
                            "button_register": "Registrer",
                            "button_update": "Oppdater",
                            "button_delete": "Slett mottaker",
                            "button_cancel": "Avbryt",
                            "success_message_register": "Mottaker ble registrert, du vil nå bli videresendt til mottakerlisten din innen 5 sekunder",
                            "success_message_update": "Mottaker ble oppdatert, du vil nå bli videresendt til mottakerlisten din innen 5 sekunder",
                            "success_message_delete": "Mottaker ble slettet, du vil nå bli videresendt til mottakerlisten din innen 5 sekunder"
                        },
                        "receiver_list": {
                            "title": "Mine mottakere",
                            "title_guardian": "Verger",
                            "no_receivers": "Du har ikke registrert noen mottakere enda",
                            "no_receiver_guardians": "Du har ikke registrert noen verger enda",
                            "add_new_receiver_button": "Legg til ny mottaker",
                            "add_new_receiver_guardian_button": "Legg til ny verge",
                            "delete_confirm_message": "Vil du slette denne mottakeren?"
                        }
                    },
                    "greeting": {
                        "show_greeting": {
                            "accept_terms": "Godta betingelser for å se hilsen",
                            "checkbox_alert_message": "Vennligst godta betingelser",
                            "button": "Vis hilsen",
                            "download_greeting_button": "Last ned hilsen som pdf",
                            "download_greeting_video_button": "Last ned video",
                            "pin_code_label": "Pinkode",
                            "pin_code_missing": "Mangler pinkode"
                        },
                        "greeting_list": {
                            "title": "Mine hilsener",
                            "no_greetings": "Du har ikke registrert noen hilsener enda",
                            "add_new_greeting_button": "Legg til ny hilsen tekst/bilde",
                            "add_new_video_greeting_button": "Legg til ny videohilsen",
                            "delete_confirm_message": "Vil du slette denne hilsen?"
                        },
                        "greeting_preview": {
                            "deliver": "Leveres",
                            "days_after_death": " dager etter død",
                            "years_after_death": " år etter død",
                            "unknown_delivery_date": " dager etter anledning",
                            "edit_receiver": "Rediger"
                        },
                        "registration_form": {
                            "title": "Registrer ny hilsen",
                            "fields": {
                                "title": {
                                    "label": "Tittel",
                                    "placeholder": "Bursdagshilsen"
                                },
                                "delivery_date": {
                                    "label": "Dato",
                                    "placeholder": "24/12/2025"
                                },
                                "delivery_days_after_death": {
                                    "label": "Antall dager etter bortgang",
                                    "placeholder": "30"
                                },
                                "delivery_years_after_death": {
                                    "label": "Dato etter gitt antall år",
                                    "label_year": "Antall år",
                                    "label_date": "Dato",
                                    "placeholder_year": "2",
                                    "placeholder_date": "24.12"
                                },
                                "unknown_delivery_date": {
                                    "label": "Anledning med ukjent dato",
                                    "label_days": "Antall dager etter anledning",
                                    "placeholder": " "
                                },
                                "status": {
                                    "label": "Aktiv",
                                    "placeholder": " "
                                }
                            },
                            "delivery_options": "Leveringsalternativer",
                            "button_register": "Publiser",
                            "button_draft": "Lagre",
                            "button_delete": "Slett hilsen",
                            "button_edit_receivers": "Rediger mottakere",
                            "button_preview": "Forhåndsvisning",
                            "button_stop_preview": "Rediger",
                            "button_add_text": "Legg til tekst",
                            "button_add_image": "Legg til bilde",
                            "button_choose_video": "Velg video",
                            "button_capture_video": "Ta opp video",
                            "button_video_greeting": "Forhåndsvisning",
                            "success_message_register": "Hilsen ble registrert",
                            "success_message_update": "Hilsen ble oppdatert",
                            "success_message_publish": "Hilsen ble publisert",
                            "success_message_delete": "Hilsen ble slettet, du vil nå bli videresendt til dine hilsener innen 5 sekunder"
                        }
                    },
                    "prepp_form": {
                        "title": "Preppskjema",
                        "description": "Her kan du legge inn dine ønsker for begravelse og andre praktiske avgjørelser som letter arbeidet for dine etterlatte",
                        "fields": {
                            "social_security_number": {
                                "label": "Skriv inn ditt fødselsnummer",
                                "placeholder": " "
                            },
                            "burial_place": {
                                "label": "Jeg ønsker min kiste/urne nedlagt ved følgende gravsted/gravlund",
                                "placeholder": " "
                            },
                            "ash_spread": {
                                "label": "Jeg ønsker min aske spredd på følgende sted",
                                "placeholder": " "
                            },
                            "funeral_agency": {
                                "label": "Hvilket begravelsesbyrå ønsker du å benytte?",
                                "placeholder": " "
                            },
                            "funeral_location": {
                                "label": "Har du et ønsket sted og lokale for gravferdsseremonien",
                                "placeholder": " "
                            },
                            "funeral_picture": {
                                "label": "Hvilket bilde av deg ønsker du skal benyttes i begravelsen din?",
                                "placeholder": " "
                            },
                            "burial_clothes": {
                                "label": "Hvilke klær(evnt. farger på klær) ønsker du å ha på deg når du begraves/kremeres?",
                                "placeholder": " "
                            },
                            "funeral_video": {
                                "label": "Har du laget en filmhilsen du vil skal fremvises til dine etterlatte i din begravelse?",
                                "placeholder": " "
                            },
                            "funeral_speech": {
                                "label": "Har du laget en tale/et brev som skal leses i begravelsen din?",
                                "placeholder": " "
                            },
                            "funeral_songs_and_poems": {
                                "label": "Har du ønsker om sanger, salmer, dikt eller liknende som skal fremføres i din begravelse?",
                                "placeholder": " "
                            },
                            "organization": {
                                "label": "Til hvilken organisasjon ønsker du at dine eventuelle minnegaver skal gå?",
                                "placeholder": " "
                            },
                            "donor": {
                                "label": "Ønsker å være donor?",
                                "yes": "Ja"
                            },
                            "donor_research": {
                                "label": "Ønsker å testamentere ditt legeme til forskning?",
                                "yes": "Ja"
                            },
                            "codes": {
                                "label": "Hvor kan etterlatte finne koder de kan behøve(Telefon, PC, Mac, Nettbank, netflix, internett)",
                                "placeholder": " "
                            },
                            "finance_and_insurance": {
                                "label": "Har du bankforbindelser, forsikringer, aksjer eller liknende etterlatte bør få kjennskap til?",
                                "placeholder": " "
                            },
                            "documents": {
                                "label": "Har du dokumenter, ekteskapsattest/ektepakt, samboerkontrakt eller liknende etterlatte bør få kjennskap til?",
                                "placeholder": " "
                            },
                            "pets": {
                                "label": "Eier du husdyr du har spesielle ønsker for?",
                                "placeholder": " "
                            },
                            "other_wishes": {
                                "label": "Har du andre ønsker mottager av denne preppen bør være klar over?",
                                "placeholder": " "
                            },
                            "funeral_form": {
                                "label": "Gravferdsform",
                                "options": {
                                    "coffin_grave": "Kistegrav",
                                    "cremation_and_urn_grave": "Kremering og urnegrav",
                                    "cremation_and_name_memorial_grove": "Kremering og navnet minnelund",
                                    "cremation_and_anonymous_memorial_grove": "Kremering og anonym minnelund",
                                    "cremation_and_ash_scattering": "Kremering og askespredning"
                                }
                            },
                            "funeral_ceremony": {
                                "label": "Gravferdsseremoni",
                                "options": {
                                    "traditional_church_ceremony": "Tradisjonell kirkelig seremoni",
                                    "humanistic_ceremony": "Humanistisk seremoni",
                                    "personal_ceremony": "Personlig seremoni",
                                    "no_ceremony": "Ingen seremoni"
                                }
                            },
                            "written_greeting": {
                                "label": "Om du vil lage en liten skriftlig hilsen til mottager av denne preppen, kan du skrive meldingen her",
                                "placeholder": " "
                            },
                            "published": {
                                "label": "Publisert",
                                "placeholder": " "
                            }
                        },
                        "message_published": "Preppskjema er publisert",
                        "message_unpublished": "Preppskjema er ikke publisert",
                        "message": "Dine mottakere vil motta dette skjema dagen etter din registrerte bortgang. Når du publiserer skjema vil også mottakerne dine få beskjed om at det finns et Preppskjema fra deg. De vil da kunne lese skjema, om de gjør det før din bortgang får du beskjed om det.",
                        "delete_document_confirmation_message": "Er du sikker på at du vil slette filen?",
                        "button_choose_picture": "Velg bilde",
                        "button_delete_picture": "Slett bilde",
                        "button_choose_video": "Velg video",
                        "button_delete_video": "Slett video",
                        "button_choose_document": "Velg dokument",
                        "button_delete_document": "Slett dokument",
                        "button_see_document": "Se dokument",
                        "download_preppform_button": "Last ned preppskjema som pdf",
                        "download_preppform_image_button": "Last ned bilde",
                        "download_preppform_video_button": "Last ned video",
                        "download_preppform_speech_button": "Last ned tale",
                        "download_preppform_document_button": "Last ned dokument",
                        "attachments": "Vedlegg",
                        "button_register": "Lagre",
                        "button_publish": "Publiser",
                        "button_unpublish": "Avpubliser",
                        "button_edit": "Rediger",
                        "success_message_register": "Skjema ble lagret"
                    },
                    "footer": {
                        "links": {
                            "privacy": "Personvern",
                            "about": "Om Prepp"
                        },
                        "text": "Prepp er en tjeneste som gjør at du kan sende personlige meldinger etter din bortgang"
                    }
                }
            }
        }
    }
);

export default i18next;

