import {configureStore, ThunkAction, Action} from '@reduxjs/toolkit';
import userReducer from './slices/authenticationSlice';
import styleReducer from './slices/styleSlice';
import counterReducer from '../features/counter/counterSlice';

export const store = configureStore({
    reducer: {
        authenticated: userReducer,
        user_id: userReducer,
        jwt: userReducer,
        counter: counterReducer,
        background_image: styleReducer
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
    RootState,
    unknown,
    Action<string>>;
