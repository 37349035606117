import React, {useState} from 'react';
import i18next from "../../languages/no/translation";

const axios = require('axios');

function NewPassword() {

    let new_password_ajax_lock = false;

    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const getNewPassword = (e: any) => {
        e.preventDefault();
        if (!new_password_ajax_lock) {
            new_password_ajax_lock = true;
            let userLoginForm = document.getElementById("user-new-password-form") as HTMLFormElement;
            let formData = new FormData(userLoginForm);
            const userObject = {} as any;
            formData.forEach((value, key) => (userObject[key] = value));
            setErrorMessage("");
            axios({
                url: "/user/new_password",
                method: "post",
                headers: {"Content-Type": "application/json"},
                data: userObject
            })
                .then(function (response: any) {
                    const obj = response.data;
                    if (obj.user_id !== undefined) {
                        setSuccessMessage(i18next.t('user.new_password_form.success_message'));
                    }
                    new_password_ajax_lock = false;
                })
                .catch(function (error: any) {
                    console.log('cde' + error.response.data.error);
                    setErrorMessage(error.response.data.error);
                    new_password_ajax_lock = false;
                });
        }
    }

    return (
        <div className="container">
            <form id="user-new-password-form" method="post" onSubmit={getNewPassword}>
                <div className="content-wrapper">
                    <div className="row my-3">
                        <div className="form-group col-md-12 my-2">
                            <label htmlFor="email">{i18next.t('user.new_password_form.fields.email.label')}</label>
                            <input type="text" className="form-control" name="email"
                                   placeholder={i18next.t('user.new_password_form.fields.email.placeholder')} required/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {errorMessage !== "" &&
                    <div className="alert alert-danger" role="alert">
                        {errorMessage}
                    </div>}
                    {successMessage !== "" &&
                    <div className="alert alert-success" role="alert">
                        {successMessage}
                    </div>}
                    <div className="form-group col-md-12">
                        <button type="submit" id="user-login-form-button" className="btn btn-primary">
                            {i18next.t('user.new_password_form.button')}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default NewPassword;