import React, {useEffect, useState} from 'react';
import i18next from '../../languages/no/translation';
import {Prompt, useHistory} from "react-router-dom";
import axios from "axios";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {selectJwt, selectUserId} from "../../store/slices/authenticationSlice";
import GreetingContentBlockImage from "./GreetingContentBlockImage";
import GreetingContentBlockText from "./GreetingContentBlockText";
import GreetingVideo from "./GreetingVideo";
import ReceiverList from "../receiver/ReceiverList";
import GreetingPreview from "./GreetingPreview";
import Receiver from "../receiver/Receiver";
import User from "../user/User";
import {setBackgroundImage} from "../../store/slices/styleSlice";
import UserTermsModal from "../user/UserTermModal";

function Greeting(props: any) {

    const dispatch = useAppDispatch();

    let register_greeting_ajax_lock = false;
    let delete_greeting_ajax_lock = false;

    const search = props.location.search;
    const params = new URLSearchParams(search);
    const isVideoGreeting = params.get('video');

    const greeting_id = props.match.params && props.match.params.greeting_id ? props.match.params.greeting_id : null;

    const authentication = useAppSelector(selectJwt);
    const user = useAppSelector(selectUserId);
    const [apiUrl, setApiUrl] = useState("/user/" + user.user_id + "/greeting/new");
    const [greeting, setGreeting] = useState<any>(null);
    const [preview, setPreview] = useState(false);
    const [deviceReady, setDeviceReady] = useState(false);
    const [approveTerm, setApproveTerm] = useState(false);
    const [showProfile, setShowProfile] = useState(false);
    const [progressBarPercent, setProgressBarPercent] = useState(0);
    const [title, setTitle] = useState("");
    const [deliveryDate, setDeliveryDate] = useState("");
    const [deliveryDaysAfterDeath, setDeliveryDaysAfterDeath] = useState("");
    const [deliveryYearsAfterDeath, setDeliveryYearsAfterDeath] = useState("");
    const [deliveryYearsAfterDeathDate, setDeliveryYearsAfterDeathDate] = useState("");
    const [deliveryOption, setDeliveryOption] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    let [apiFinished, setApiFinished] = useState(false);
    const [formChanged, setFormChanged] = useState(false);
    const [editReceivers, setEditReceivers] = useState(false);
    const history = useHistory();
    const registerGreeting = (e: any) => {
        e.preventDefault();
        setFormChanged(false);
        if (!register_greeting_ajax_lock) {
            register_greeting_ajax_lock = true;
            let greetingRegistrationForm = document.getElementById("greeting-registration-form") as HTMLFormElement;
            let formData = new FormData(greetingRegistrationForm);
            const greetingObject = {} as any;
            formData.forEach((value, key) => (greetingObject[key] = value));
            if (approveTerm) {
                greetingObject.title = title;
                greetingObject.delivery_date = deliveryDate;
                greetingObject.delivery_days_after_death = deliveryDaysAfterDeath;
                greetingObject.delivery_years_after_death = deliveryYearsAfterDeath;
                greetingObject.delivery_years_after_death_date = deliveryYearsAfterDeathDate;
            }
            if (deliveryOption == 'unknown_delivery_date') {
                greetingObject.unknown_delivery_date = 1;
            }
            if (deliveryOption == 'delivery_date') {
                greetingObject.delivery_days_after_death = null;
                greetingObject.delivery_years_after_death = null;
                greetingObject.delivery_years_after_death_date = null;
            }
            if (deliveryOption == 'delivery_days_after_death' || deliveryOption == 'unknown_delivery_date') {
                greetingObject.delivery_date = null;
                greetingObject.delivery_years_after_death = null;
                greetingObject.delivery_years_after_death_date = null;
            }
            if (deliveryOption == 'delivery_years_after_death') {
                greetingObject.delivery_date = null;
                greetingObject.delivery_days_after_death = null;
            }
            // @ts-ignore
            greetingObject.receivers = getReceivers();
            greetingObject.receiver_guardians = receiverGuardianList;
            // @ts-ignore
            greetingObject.video = video;
            greetingObject.video_duration = videoDuration;
            // @ts-ignore
            greetingObject.greeting_content_blocks = GreetingContentBlockArray;
            setErrorMessage("");
            // @ts-ignore
            axios({
                url: apiUrl,
                method: "post",
                headers: {
                    "Accept": "application/json",
                    "Authorization": authentication.jwt
                },
                data: greetingObject,
                onUploadProgress: progressEvent => {
                    progressBar(progressEvent);
                }
            })
                .then(function (response: any) {
                    const obj = response.data;
                    if (obj.greeting_id !== undefined) {
                        if (greeting && obj.status == 1) {
                            setSuccessMessage(i18next.t('greeting.registration_form.success_message_publish'));
                        }
                        if (greeting && !obj.status) {
                            setSuccessMessage(i18next.t('greeting.registration_form.success_message_update'));
                        }
                        if (!greeting && !obj.status) {
                            setSuccessMessage(i18next.t('greeting.registration_form.success_message_register'));
                            setApiUrl("/user/" + user.user_id + "/greeting/" + obj.greeting_id + "/edit");
                        }
                        if (!greeting && obj.status == 1) {
                            setSuccessMessage(i18next.t('greeting.registration_form.success_message_register'));
                        }
                        if (editReceivers) {
                            setEditReceivers(false);
                        }
                        if (obj.status == 1) {
                            window.location.href = "/greeting/" + obj.greeting_id + (isVideoGreeting ? "?video=1" : "");
                        }
                        setTimeout(function () {
                            setSuccessMessage("");
                            if (!greeting) {
                                history.push("/greeting/" + obj.greeting_id + (isVideoGreeting ? "?video=1" : ""));
                            }
                        }, 3000);
                    }
                    register_greeting_ajax_lock = false;
                })
                .catch(function (error: any) {
                    setErrorMessage(error.response.data.error);
                    if (error.response.data.missing_user_info == true) {
                        setShowProfile(true);
                    }
                    register_greeting_ajax_lock = false;
                });
        }
    }

    function getGreeting() {
        if (!isNaN(greeting_id) && greeting_id !== null) {
            // @ts-ignore
            axios({
                url: "/user/" + user.user_id + "/greeting/" + greeting_id,
                method: "get",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": authentication.jwt
                }
            })
                .then(function (response: any) {
                    const obj = response.data;
                    setGreeting(obj.greeting);
                    setReceiverList(obj.receivers);
                    setReceiverGuardianList(obj.receiver_guardians);
                    updateMinorList(obj.receiver_guardians);
                    setTitle(obj.greeting.title);
                    setDeliveryDate(obj.greeting.delivery_date);
                    setDeliveryDaysAfterDeath(obj.greeting.delivery_days_after_death);
                    setDeliveryYearsAfterDeath(obj.greeting.delivery_years_after_death);
                    setDeliveryYearsAfterDeathDate(obj.greeting.delivery_years_after_death_date);
                    if (obj.greeting.delivery_date != '') {
                        setDeliveryOption('delivery_date');
                    }
                    if (obj.greeting.delivery_days_after_death != '' && obj.greeting.unknown_delivery_date != '1') {
                        setDeliveryOption('delivery_days_after_death');
                    }
                    if (obj.greeting.delivery_years_after_death != '' || obj.greeting.delivery_years_after_death_date) {
                        setDeliveryOption('delivery_years_after_death');
                    }
                    if (obj.greeting.unknown_delivery_date == '1') {
                        setDeliveryOption('unknown_delivery_date');
                    }
                    if (obj.video) {
                        setVideo(obj.video);
                    }
                    if (obj.greeting_content_block) {
                        setGreetingContentBlockArray(obj.greeting_content_block);
                    }
                    setPreview(true);
                    setApiUrl("/user/" + user.user_id + "/greeting/" + greeting_id + "/edit");
                    setApiFinished(true);
                })
                .catch(function (error: any) {

                });
        } else {
            setApiFinished(true);
        }
    }

    const [GreetingContentBlockArray, setGreetingContentBlockArray] = useState<any[]>([]);
    const addBlock = (blockType: string) => {
        if (blockType === 'image') {
            let newGreetingContentBlock = {
                'type': 'image',
                'image': '',
                'body': '',
                'list_order': GreetingContentBlockArray.length + 1

            }
            setGreetingContentBlockArray(GreetingContentBlockArray.concat(newGreetingContentBlock));
        }
        if (blockType === 'text') {
            let newGreetingContentBlock = {
                'type': 'text',
                'body': '',
                'list_order': GreetingContentBlockArray.length + 1

            }
            setGreetingContentBlockArray(GreetingContentBlockArray.concat(newGreetingContentBlock));
        }
    }

    const progressBar = (progressEvent: any) => {
        if (progressEvent.loaded == progressEvent.total) {
            setProgressBarPercent(0);
        }
        setProgressBarPercent(Math.round(progressEvent.loaded / progressEvent.total * 100));
    }

    const removeBlock = (listId: number) => {
        const GreetingContentBlockList = [...GreetingContentBlockArray];
        GreetingContentBlockList.splice(listId, 1);
        setGreetingContentBlockArray(GreetingContentBlockList);
    };

    const [video, setVideo] = useState<any>(null);
    const setGreetingContentVideo = (video: any) => {
        setVideo(video);
    };

    const [videoDuration, setVideoDuration] = useState<any>(null);
    const setGreetingContentVideoDuration = (videoDuration: any) => {
        setVideoDuration(videoDuration);
    };

    const setGreetingContentBlockImage = (image: any, listId: number) => {
        const GreetingContentBlockList = [...GreetingContentBlockArray];
        for (let block of GreetingContentBlockList) {
            GreetingContentBlockList[listId].image = image;
        }
        setGreetingContentBlockArray(GreetingContentBlockList);
    };

    const setGreetingContentBlockBody = (body: any, listId: number) => {
        const GreetingContentBlockList = [...GreetingContentBlockArray];
        for (let block of GreetingContentBlockList) {
            GreetingContentBlockList[listId].body = body;
        }
        setGreetingContentBlockArray(GreetingContentBlockList);
    };

    const swapListOrder = (dragListOrder: number, dropListOrder: number) => {
        const GreetingContentBlockList = [...GreetingContentBlockArray];
        const dragListOrderIndex = GreetingContentBlockList.findIndex((item, index) => {
            if (item.list_order === dragListOrder) {
                return true;
            }
        });
        const dropListOrderIndex = GreetingContentBlockList.findIndex((item, index) => {
            if (item.list_order === dropListOrder) {
                return true;
            }
        });
        GreetingContentBlockList[dragListOrderIndex].list_order = dropListOrder;
        GreetingContentBlockList[dropListOrderIndex].list_order = dragListOrder;
        GreetingContentBlockList.sort((a, b) => parseInt(a.list_order) - parseInt(b.list_order));
        setGreetingContentBlockArray(GreetingContentBlockList);
    };

    const selectPreview = () => {
        if (preview) {
            setPreview(false);
        } else {
            setPreview(true);
        }
    };

    const selectApproveTerm = () => {
        setSuccessMessage("");
        setErrorMessage("");
        if (approveTerm) {
            dispatch(setBackgroundImage('/images/clouds.jpg'));
            setApproveTerm(false);
        } else {
            dispatch(setBackgroundImage('/images/man-purple.jpg'));
            // @ts-ignore
            setTitle(document.getElementById("title").value);
            // @ts-ignore
            setDeliveryDate(document.getElementById("delivery_date").value);
            // @ts-ignore
            setDeliveryDaysAfterDeath(document.getElementById("delivery_days_after_death").value);
            setApproveTerm(true);
        }
    };

    function updateTitle() {
        // @ts-ignore
        let title = document.getElementById("title").value;
        setTitle(title);
        setFormChanged(true);
    }

    function updateDeliveryDate() {
        // @ts-ignore
        let deliveryDate = document.getElementById("delivery_date").value;
        setDeliveryDate(deliveryDate);
        setFormChanged(true);
    }

    function updateDeliveryDaysAfterDeath() {
        // @ts-ignore
        let deliveryDaysAfterDeath = document.getElementById("delivery_days_after_death").value;
        setDeliveryDaysAfterDeath(deliveryDaysAfterDeath);
        setFormChanged(true);
    }

    function updateDeliveryYearsAfterDeath() {
        // @ts-ignore
        let deliveryYearsAfterDeath = document.getElementById("delivery_years_after_death").value;
        setDeliveryYearsAfterDeath(deliveryYearsAfterDeath);
        setFormChanged(true);
    }

    function updateDeliveryYearsAfterDeathDate() {
        // @ts-ignore
        let deliveryYearsAfterDeathDate = document.getElementById("delivery_years_after_death_date").value;
        setDeliveryYearsAfterDeathDate(deliveryYearsAfterDeathDate);
        setFormChanged(true);
    }


    const [receiverList, setReceiverList] = useState<any[]>([]);
    const [minorList, setMinorList] = useState<any[]>([]);
    const addOrRemoveReceiver = (receiverId: number, minor: boolean) => {
        let receiverListArray = [...receiverList];
        // @ts-ignore
        let receiverIndex = receiverListArray.indexOf(receiverId);
        if (receiverIndex < 0) {
            // @ts-ignore
            receiverListArray.push(receiverId);
        } else {
            receiverListArray.splice(receiverIndex, 1);
        }
        setReceiverList(receiverListArray);

        if (minor) {
            let minorListArray = [...minorList];
            let minorIndex = minorListArray.indexOf(receiverId);
            if (minorIndex < 0) {
                // @ts-ignore
                minorListArray.push(receiverId);
            } else {
                minorListArray.splice(minorIndex, 1);
            }
            setMinorList(minorListArray);
        }

    };

    const updateMinorList = (receiverList: any) => {
        let minorListArray = [...minorList];
        receiverList.forEach((element: any) => {
            let minorIndex = minorListArray.indexOf(element.receiver_id);
            if (minorIndex < 0) {
                // @ts-ignore
                minorListArray.push(element.receiver_id);
            }
        });
        setMinorList(minorListArray);
    };

    const [receiverGuardianList, setReceiverGuardianList] = useState<any[]>([]);
    const addOrRemoveReceiverGuardian = (minorReceiverId: number, receiverGuardianId: number) => {
        let receiverGuardianListArray = [...receiverGuardianList];

        let indexOfReceiverAndReceiverGuardianInArray = null;
        receiverGuardianListArray.forEach(function (item, index) {
            if (item.receiver_id == minorReceiverId && item.receiver_guardian_id == receiverGuardianId) {
                indexOfReceiverAndReceiverGuardianInArray = index;
            }
        });

        if (indexOfReceiverAndReceiverGuardianInArray == null) {
            receiverGuardianListArray.push(
                {
                    "receiver_id": minorReceiverId,
                    "receiver_guardian_id": receiverGuardianId
                }
            );
        }

        if (indexOfReceiverAndReceiverGuardianInArray != null) {
            receiverGuardianListArray.splice(indexOfReceiverAndReceiverGuardianInArray, 1);
        }

        setReceiverGuardianList(receiverGuardianListArray);
    };

    const getReceivers = () => {
        const receiverListArray = [];
        for (let i = 0; i < receiverList.length; i++) {
            receiverListArray.push({"receiver_id": receiverList[i]});
        }
        return receiverListArray;
    }

    const onDragStart = (event: any) => {
        let dragListOrder = Number(event.target.dataset.listorder);
        event.dataTransfer.setData('list-order', dragListOrder);
    }
    const onDragLeave = (event: any) => {
        event.preventDefault();
        event.currentTarget.classList.remove('over');

    }
    const onDragOver = (event: any) => {
        event.preventDefault();
        event.currentTarget.classList.add('over');
    }
    const onDrop = (event: any) => {
        event.preventDefault();
        let dragListOrder = Number(event.dataTransfer.getData('list-order'));
        let dropListOrder = Number(event.currentTarget.dataset.listorder);
        if (!isNaN(dragListOrder) && !isNaN(dropListOrder) && dragListOrder !== dropListOrder) {
            event.currentTarget.classList.remove('over');
            swapListOrder(dragListOrder, dropListOrder);
        }
    }

    let greetingContentBlocks = GreetingContentBlockArray.map((greetingContentBlock, index: number) => {
        if (greetingContentBlock.type == 'image') {
            return (
                <li data-listorder={greetingContentBlock.list_order} onDragLeave={onDragLeave} onDragStart={onDragStart}
                    onDragOver={onDragOver}
                    onDrop={onDrop} draggable={true} className="m-0 p-0"><GreetingContentBlockImage
                    greetingContentBlock={greetingContentBlock}
                    swapListOrder={(dragListOrder: number, dropListOrder: number) => swapListOrder(dragListOrder, dropListOrder)}
                    removeBlock={(index: number) => removeBlock(index)}
                    setGreetingContentBlockBody={(body: any, index: number) => setGreetingContentBlockBody(body, index)}
                    setGreetingContentBlockImage={(image: any, index: number) => setGreetingContentBlockImage(image, index)}
                    setFormChanged={(changed: boolean) => setFormChanged(changed)} key={index} listId={index}/></li>);
        }
        if (greetingContentBlock.type == 'text') {
            return (
                <li data-listorder={greetingContentBlock.list_order} onDragLeave={onDragLeave} onDragStart={onDragStart}
                    onDragOver={onDragOver}
                    onDrop={onDrop} draggable={true} className="m-0 p-0"><GreetingContentBlockText
                    greetingContentBlock={greetingContentBlock}
                    swapListOrder={(dragListOrder: number, dropListOrder: number) => swapListOrder(dragListOrder, dropListOrder)}
                    removeBlock={(index: number) => removeBlock(index)}
                    setGreetingContentBlockBody={(body: any, index: number) => setGreetingContentBlockBody(body, index)}
                    setFormChanged={(changed: boolean) => setFormChanged(changed)} key={index} listId={index}/></li>);
        }
    })

    const deleteGreeting = (e: any) => {
        e.preventDefault();
        if (!delete_greeting_ajax_lock) {
            delete_greeting_ajax_lock = true;
            setErrorMessage("");
            if (!isNaN(greeting_id) && greeting_id !== null) {
                if (!window.confirm(i18next.t('greeting.greeting_list.delete_confirm_message'))) {
                    return false;
                }
                // @ts-ignore
                axios({
                    url: "/user/" + user.user_id + "/greeting/" + greeting_id + "/delete",
                    method: "get",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": authentication.jwt
                    }
                })
                    .then(function (response: any) {
                        const obj = response.data;
                        if (obj.greeting_id !== undefined) {
                            setSuccessMessage(i18next.t('greeting.registration_form.success_message_delete'));
                            setTimeout(function () {
                                history.push("/greetings");
                            }, 5000);
                        }
                        delete_greeting_ajax_lock = false;
                    })
                    .catch(function (error: any) {
                        setErrorMessage(error.response.data.error);
                        delete_greeting_ajax_lock = false;
                    });
            }
        }
    }

    const saveAsDraft = () => {
        // @ts-ignore
        document.getElementById("status").checked = false;
    }

    const saveAsPublished = () => {
        // @ts-ignore
        document.getElementById("status").checked = true;
    }

    function onDeviceReady() {
        setDeviceReady(true);
    }

    useEffect(() => {
        getGreeting();
        if (process.env.REACT_APP_PLATTFORM != 'web') {
            document.addEventListener("deviceready", onDeviceReady, false);
        }
    }, []);

    let [addReceiver, setAddReceiver] = useState(false);
    let [isMinor, setIsMinor] = useState(false);
    let [minorReceiverId, setMinorReceiverId] = useState(null);
    const addReceiverToggle = () => {
        if (addReceiver) {
            setAddReceiver(false);
            setMinorReceiverId(null);
            setIsMinor(false);
        } else {
            setAddReceiver(true);
        }
    }

    const addGuardianToggle = (minorReceiverId: number) => {
        setIsMinor(true);
        // @ts-ignore
        setMinorReceiverId(minorReceiverId);
        addReceiverToggle();
    }

    const enableUserTermCheckbox = (enable: boolean) => {
        if (enable) {
            // @ts-ignore
            document.getElementById("terms").disabled = false;
        }
    }

    return (
        <div className="container">
            <Prompt when={formChanged} message={i18next.t('user.registration_form.form_has_changed')}/>
            {addReceiver &&
            <Receiver isMinor={isMinor} minorReceiverId={minorReceiverId} fromGreeting={true}
                      addReceiverToggle={addReceiverToggle}
                      addOrRemoveReceiver={(receiverId: number, minor: boolean) => addOrRemoveReceiver(receiverId, minor)}
                      addOrRemoveReceiverGuardian={(minorReceiverId: number, receiverGuardianId: number) => addOrRemoveReceiverGuardian(minorReceiverId, receiverGuardianId)}/>
            }
            {apiFinished && !addReceiver &&
            <form className="clearfix" id="greeting-registration-form" method="post" encType="multipart/form-data"
                  onSubmit={registerGreeting}>
                <div className="content-wrapper">
                    {!approveTerm && !preview &&
                    <>
                        {isVideoGreeting && showProfile &&
                        <User fromGreeting={true}/>}
                        {isVideoGreeting &&
                        <GreetingVideo video={video} deviceReady={deviceReady}
                                       setGreetingContentVideo={(video: any) => setGreetingContentVideo(video)}
                                       setGreetingContentVideoDuration={(video: any) => setGreetingContentVideoDuration(video)}
                                       setFormChanged={(changed: boolean) => setFormChanged(changed)}/>
                        }
                        {!isVideoGreeting &&
                        <ul id="block-type" className="m-0 p-0">
                            {greetingContentBlocks}
                        </ul>
                        }
                        {!isVideoGreeting &&
                        <div className="row my-3">
                            <div className="col-12">
                                <button onClick={() => addBlock('text')} type="button"
                                        className="btn btn-primary">{i18next.t('greeting.registration_form.button_add_text')}
                                </button>
                                <button onClick={() => addBlock('image')} type="button"
                                        className="btn btn-primary ms-3">{i18next.t('greeting.registration_form.button_add_image')}
                                </button>
                            </div>
                        </div>
                        }

                        <div className="row my-3">

                            <div className="row mb-3">
                                <div className="form-group col-md-12">
                                    <label
                                        htmlFor="title">{i18next.t('greeting.registration_form.fields.title.label')}</label>
                                    <input type="text" className="form-control" id="title" name="title"
                                           onChange={updateTitle}
                                           defaultValue={title != '' ? title : greeting && greeting.title}
                                           placeholder={i18next.t('greeting.registration_form.fields.title.placeholder')}
                                    />
                                </div>
                            </div>
                            <div className="form-group col-md-12">
                                {i18next.t('greeting.registration_form.delivery_options')}
                                <div className="form-check">
                                    <input className="form-check-input" type="radio"
                                           id="delivery_options_is_delivery_date" value="delivery_date"
                                           checked={deliveryOption == 'delivery_date'}
                                           onChange={e => setDeliveryOption(e.currentTarget.value)}/>
                                    <label className="form-check-label"
                                           htmlFor="delivery_options_is_delivery_date">
                                        {i18next.t('greeting.registration_form.fields.delivery_date.label')}
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio"
                                           id="delivery_options_is_delivery_days_after_death"
                                           value="delivery_days_after_death"
                                           checked={deliveryOption == 'delivery_days_after_death'}
                                           onChange={e => setDeliveryOption(e.currentTarget.value)}/>
                                    <label className="form-check-label"
                                           htmlFor="delivery_options_is_delivery_days_after_death">
                                        {i18next.t('greeting.registration_form.fields.delivery_days_after_death.label')}
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio"
                                           id="delivery_options_is_delivery_years_after_death"
                                           value="delivery_years_after_death"
                                           checked={deliveryOption == 'delivery_years_after_death'}
                                           onChange={e => setDeliveryOption(e.currentTarget.value)}/>
                                    <label className="form-check-label"
                                           htmlFor="delivery_options_is_delivery_years_after_death">
                                        {i18next.t('greeting.registration_form.fields.delivery_years_after_death.label')}
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio"
                                           id="delivery_options_is_unknown_delivery_date" value="unknown_delivery_date"
                                           checked={deliveryOption == 'unknown_delivery_date'}
                                           onChange={e => setDeliveryOption(e.currentTarget.value)}/>
                                    <label className="form-check-label"
                                           htmlFor="delivery_options_is_unknown_delivery_date">
                                        {i18next.t('greeting.registration_form.fields.unknown_delivery_date.label')}
                                    </label>
                                </div>
                            </div>
                            {deliveryOption == 'delivery_date' &&
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <label
                                        htmlFor="delivery_date">{i18next.t('greeting.registration_form.fields.delivery_date.label')}</label>
                                    <input type="date" className="form-control" id="delivery_date" name="delivery_date"
                                           onChange={updateDeliveryDate}
                                           defaultValue={deliveryDate != '' ? deliveryDate : greeting && greeting.delivery_date}
                                           placeholder={i18next.t('greeting.registration_form.fields.delivery_date.placeholder')}
                                    />
                                </div>
                            </div>}
                            {(deliveryOption == 'delivery_days_after_death' || deliveryOption == 'unknown_delivery_date') &&
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <label
                                        htmlFor="delivery_days_after_death">{(deliveryOption == 'unknown_delivery_date' ? i18next.t('greeting.registration_form.fields.unknown_delivery_date.label_days') : i18next.t('greeting.registration_form.fields.delivery_days_after_death.label'))}</label>
                                    <input type="text" className="form-control" id="delivery_days_after_death"
                                           name="delivery_days_after_death" onChange={updateDeliveryDaysAfterDeath}
                                           defaultValue={deliveryDaysAfterDeath != '' ? deliveryDaysAfterDeath : greeting && greeting.delivery_days_after_death}
                                           placeholder={i18next.t('greeting.registration_form.fields.delivery_days_after_death.placeholder')}/>
                                </div>
                            </div>}
                            {deliveryOption == 'delivery_years_after_death' &&
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label
                                                htmlFor="delivery_years_after_death">{i18next.t('greeting.registration_form.fields.delivery_years_after_death.label_year')}</label>
                                            <input type="text" className="form-control" id="delivery_years_after_death"
                                                   name="delivery_years_after_death"
                                                   onChange={updateDeliveryYearsAfterDeath}
                                                   defaultValue={deliveryYearsAfterDeath != '' ? deliveryYearsAfterDeath : greeting && greeting.delivery_years_after_death}
                                                   placeholder={i18next.t('greeting.registration_form.fields.delivery_years_after_death.placeholder_year')}/>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label
                                                htmlFor="delivery_years_after_death_date">{i18next.t('greeting.registration_form.fields.delivery_years_after_death.label_date')}</label>
                                            <input type="text" className="form-control"
                                                   id="delivery_years_after_death_date"
                                                   name="delivery_years_after_death_date"
                                                   onChange={updateDeliveryYearsAfterDeathDate}
                                                   defaultValue={deliveryYearsAfterDeathDate != '' ? deliveryYearsAfterDeathDate : greeting && greeting.delivery_years_after_death_date}
                                                   placeholder={i18next.t('greeting.registration_form.fields.delivery_years_after_death.placeholder_date')}/>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                        <div className="row mb-3">
                            <div className="form-group col-md-6">
                                <div className="form-check">
                                    <input className="form-check-input d-none" type="checkbox" value="1" id="status"
                                           name="status"
                                           defaultChecked={greeting && greeting.status}/>
                                </div>
                            </div>
                        </div>
                    </>}
                    {!approveTerm && preview &&
                    <GreetingPreview editReceivers={editReceivers} receiverList={receiverList} title={title}
                                     deliveryDate={deliveryDate} deliveryOption={deliveryOption}
                                     deliveryYearsAfterDeath={deliveryYearsAfterDeath}
                                     deliveryYearsAfterDeathDate={deliveryYearsAfterDeathDate}
                                     deliveryDaysAfterDeath={deliveryDaysAfterDeath} isVideoGreeting={isVideoGreeting}
                                     video={video}
                                     greetingContentBlocks={GreetingContentBlockArray}/>}

                    {((!approveTerm && !preview) || editReceivers) &&
                    <ReceiverList minorList={minorList} receiverList={receiverList}
                                  receiverGuardianList={receiverGuardianList} fromGreeting={true}
                                  addReceiverToggle={addReceiverToggle}
                                  addGuardianToggle={(minorReceiverId: number) => addGuardianToggle(minorReceiverId)}
                                  addOrRemoveReceiver={(receiverId: number, minor: boolean) => addOrRemoveReceiver(receiverId, minor)}
                                  addOrRemoveReceiverGuardian={(minorReceiverId: number, receiverGuardianId: number) => addOrRemoveReceiverGuardian(minorReceiverId, receiverGuardianId)}/>}
                    <div className="row mb-3">
                        <div className="form-group col-md-6">
                            <div className="form-check">
                                <input className="form-check-input d-none" type="checkbox" value="1" id="status"
                                       name="status"
                                       defaultChecked={greeting && greeting.status}/>
                            </div>
                        </div>
                    </div>
                    {approveTerm &&
                    <div className="row mb-3 p-2">
                        <div className="col-12">
                            <p>Hei, du skal nå publisere din hilsen.</p>
                            <p>Vi vi da levere din hilsen til den/de du har valgt til det tidspunktet som er valgt.</p>
                            <p>Kryss av for at du har lest våre vilkår og trykk Publiser igjen.</p>
                        </div>
                        <div className="col-12">
                            <div className="form-check">
                                <input className="form-check-input " type="checkbox" id="terms" name="terms" disabled
                                       required/>
                                <label className="form-check-label">
                                    <button type="button" className="btn btn-link pt-0" data-bs-toggle="modal"
                                            data-bs-target="#user-term-modal">{i18next.t('user.registration_form.fields.terms.label')}</button>
                                </label>
                            </div>
                        </div>
                        <UserTermsModal enableUserTermCheckbox={(enable: boolean) => enableUserTermCheckbox(enable)}/>
                    </div>}
                </div>
                {errorMessage !== "" &&
                <div className="alert alert-danger" role="alert">
                    {errorMessage}
                </div>}
                {successMessage !== "" &&
                <div className="alert alert-success" role="alert">
                    {successMessage}
                </div>}
                {successMessage === "" && errorMessage === "" && progressBarPercent > 0 && progressBarPercent < 100 &&
                <div className="my-3 progress-bar-background">
                    <div className="progress-bar" style={{height: "24px", width: {progressBarPercent} + "%"}}></div>
                </div>}
                {!approveTerm && !(greeting && greeting.status) && !preview &&
                <button onClick={selectApproveTerm} type="button" id="greeting-term-button"
                        className="btn btn-primary float-end">
                    {i18next.t('greeting.registration_form.button_register')}
                </button>}
                {!approveTerm && !(greeting && greeting.status) && !preview &&
                <button onClick={saveAsDraft} type="submit" id="greeting-registration-as-draft-form-button"
                        className="btn btn-primary float-end me-2">
                    {i18next.t('greeting.registration_form.button_draft')}
                </button>}
                {!approveTerm && !(greeting && greeting.status) &&
                <button onClick={selectPreview} type="button" id="greeting-preview-button"
                        className="btn btn-primary float-end me-2">
                    {!preview && i18next.t('greeting.registration_form.button_preview')}
                    {preview && i18next.t('greeting.registration_form.button_stop_preview')}
                </button>}
                {!approveTerm && greeting && ((!preview && !greeting.status) || greeting.status == 1) &&
                <>
                    <button onClick={deleteGreeting} type="button" id="greeting-delete-button"
                            className="btn btn-danger float-start" data-greeting-id={greeting.greeting_id}>
                        {i18next.t('greeting.registration_form.button_delete')}
                    </button>
                    {!editReceivers &&
                    <button onClick={() => setEditReceivers(true)} type="button" id="greeting-edit-receiver-button"
                            className="btn btn-primary float-end" data-greeting-id={greeting.greeting_id}>
                        {i18next.t('greeting.registration_form.button_edit_receivers')}
                    </button>}
                </>}
                {(approveTerm || editReceivers) &&
                <button onClick={saveAsPublished} type="submit" id="greeting-registration-form-button"
                        className="btn btn-primary float-end">
                    {i18next.t('greeting.registration_form.button_register')}
                </button>}
                {approveTerm &&
                <button onClick={selectApproveTerm} type="button" id="greeting-term-button"
                        className="btn btn-primary float-end me-2">
                    {i18next.t('greeting.registration_form.button_stop_preview')}
                </button>}
            </form>
            }
        </div>
    );
}

export default Greeting;