import React, {useEffect, useState} from 'react';
import axios from "axios";
import GreetingVideoPreview from "../greeting/GreetingVideoPreview";
import GreetingContentBlockImagePreview from "../greeting/GreetingContentBlockImagePreview";
import GreetingContentBlockTextPreview from "../greeting/GreetingContentBlockTextPreview";
import i18next from "../../languages/no/translation";
import ReceiverTermsModal from "../receiver/ReceiverTermModal";

function GreetingForReceiver(props: any) {

    let view_greeting_ajax_lock = false;

    const token = props.match.params && props.match.params.token ? props.match.params.token : null;

    const [title, setTitle] = useState("");
    const [greeting, setGreeting] = useState<any>(null);
    const [isVideoGreeting, setVideoGreeting] = useState(false);
    const [video, setVideo] = useState("");
    const [greetingContentBlockArray, setGreetingContentBlockArray] = useState<any[]>([]);
    const [pdfDownloadLink, setPdfDownloadLink] = useState("");
    const [videoDownloadLink, setVideoDownloadLink] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    function getGreeting() {
        setErrorMessage("");
        // @ts-ignore
        if (!document.getElementById('terms').checked) {
            alert(i18next.t('greeting.show_greeting.checkbox_alert_message'));
            return false;
        }

        // @ts-ignore
        let pinCode = document.getElementById('pin_code').value;
        if (pinCode == "") {
            setErrorMessage(i18next.t('greeting.show_greeting.pin_code_missing'));
            return false;
        }

        if (token !== null) {
            if (!view_greeting_ajax_lock) {
                view_greeting_ajax_lock = true;
                const dataObject = {} as any;
                dataObject['token'] = token;
                dataObject['pin_code'] = pinCode;
                // @ts-ignore
                axios({
                    url: "/greeting/show_to_receiver",
                    method: "post",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    data: dataObject,
                })
                    .then(function (response: any) {
                        const obj = response.data;
                        setGreeting(obj.greeting);
                        setTitle(obj.greeting.title);
                        if (obj.video) {
                            setVideo(obj.video);
                            setVideoGreeting(true);
                        }
                        if (obj.greeting_content_block) {
                            setGreetingContentBlockArray(obj.greeting_content_block);
                            setVideoGreeting(false);
                        }
                        if (obj.pdf_download_link) {
                            setPdfDownloadLink(obj.pdf_download_link);
                        }
                        if (obj.video_download_link) {
                            setVideoDownloadLink(obj.video_download_link);
                        }
                        view_greeting_ajax_lock = false;
                    })
                    .catch(function (error: any) {
                        setErrorMessage(error.response.data.error);
                        view_greeting_ajax_lock = false;
                    });
            }
        }

    }

    const checkForSubmit = (event: any) => {
        if (event.which === 13) {
            getGreeting()
        }
    }

    let greetingContentBlocksPreview = greetingContentBlockArray.map((greetingContentBlock: { type: string; }, index: number) => {
        if (greetingContentBlock.type == 'image') {
            return (<GreetingContentBlockImagePreview greetingContentBlock={greetingContentBlock} key={index}/>);
        }
        if (greetingContentBlock.type == 'text') {
            return (<GreetingContentBlockTextPreview greetingContentBlock={greetingContentBlock} key={index}/>);
        }
    });

    const enableReceiverTermCheckbox = (enable: boolean) => {
        if (enable) {
            // @ts-ignore
            document.getElementById("terms").disabled = false;
        }
    }


    return (
        <div className="container">
            <div className="content-wrapper">
                {!greeting &&
                <>
                    <div className="row my-3 text-start">
                        <div className="form-group col-md-6">
                            <label
                                htmlFor="pin_code">{i18next.t('greeting.show_greeting.pin_code_label')}</label>
                            <input onKeyPress={checkForSubmit} type="text" className="form-control" name="pin_code"
                                   id="pin_code"/>
                        </div>
                    </div>
                    <div className="row my-3 text-start">
                        <div className="form-group col-md-6">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="terms" name="terms" disabled
                                       required/>
                                <label className="form-check-label">
                                    <button type="button" className="btn btn-link pt-0" data-bs-toggle="modal"
                                            data-bs-target="#receiver-term-modal">{i18next.t('greeting.show_greeting.accept_terms')}</button>
                                </label>
                            </div>
                            {errorMessage !== "" &&
                            <div className="alert alert-danger" role="alert">
                                {errorMessage}
                            </div>}
                            <button onClick={getGreeting} type="button" id="greeting-get-button"
                                    className="btn btn-primary">
                                {i18next.t('greeting.show_greeting.button')}
                            </button>
                        </div>
                    </div>
                </>}
                {greeting &&
                <div className="row my-3">
                    <div className="col-12 greeting-title-preview">
                        {title}
                    </div>
                    <div className="col-12">
                        {!isVideoGreeting && greetingContentBlockArray.length !== 0 &&
                        greetingContentBlocksPreview
                        }
                        {isVideoGreeting &&
                        <GreetingVideoPreview video={video}/>}
                    </div>
                    <div className="col-12 mt-4">
                        {isVideoGreeting && videoDownloadLink &&
                        <a href={videoDownloadLink} target="_blank" className="btn btn-primary"
                           download={title}>{i18next.t('greeting.show_greeting.download_greeting_video_button')}</a>}
                        {!isVideoGreeting && pdfDownloadLink &&
                        <a href={pdfDownloadLink} target="_blank"
                           className="btn btn-primary">{i18next.t('greeting.show_greeting.download_greeting_button')}</a>}
                    </div>
                </div>}
            </div>
            <ReceiverTermsModal enableReceiverTermCheckbox={(enable: boolean) => enableReceiverTermCheckbox(enable)}/>
        </div>
    );
}

export default GreetingForReceiver;