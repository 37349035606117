import React from 'react';
import 'material-icons/iconfont/material-icons.scss';

function GreetingPreview(props: any) {

    return (
        <div className="m-4">
            <div className="row">
                <div className="col-12">
                    <video controls preload="metadata" className="greeting-video-wrapper">
                        <source id="video_preview" type="video/mp4" src={props.video}/>
                    </video>
                </div>
            </div>
        </div>
    );
}

export default GreetingPreview;