import React from 'react';
import 'material-icons/iconfont/material-icons.scss';
import GreetingContentBlockPreview from "./GreetingContentBlockImagePreview";
import GreetingVideoPreview from "./GreetingVideoPreview";
import GreetingContentBlockImagePreview from './GreetingContentBlockImagePreview';
import GreetingContentBlockTextPreview from "./GreetingContentBlockTextPreview";
import ReceiverList from "../receiver/ReceiverList";
import i18next from "../../languages/no/translation";

function GreetingPreview(props: any) {

    let greetingContentBlocksPreview = props.greetingContentBlocks.map((greetingContentBlock: { type: string; }, index: number) => {
        if (greetingContentBlock.type == 'image') {
            return (<GreetingContentBlockImagePreview greetingContentBlock={greetingContentBlock} key={index}/>);
        }
        if (greetingContentBlock.type == 'text') {
            return (<GreetingContentBlockTextPreview greetingContentBlock={greetingContentBlock} key={index}/>);
        }
    })

    const deliveryOptionText = (props: any) => {
        if (props.deliveryOption == 'delivery_date') {
            return props.deliveryDate;
        }
        if (props.deliveryOption == 'delivery_days_after_death') {
            return (props.deliveryDaysAfterDeath + i18next.t('greeting.greeting_preview.days_after_death'));
        }
        if (props.deliveryOption == 'delivery_years_after_death') {
            return (props.deliveryYearsAfterDeathDate + ' ' + props.deliveryYearsAfterDeath + i18next.t('greeting.greeting_preview.years_after_death'));
        }
        if (props.deliveryOption == 'unknown_delivery_date') {
            return (props.deliveryDaysAfterDeath + i18next.t('greeting.greeting_preview.unknown_delivery_date'));
        }
    }

    return (
        <div className="m-4">
            <div className="row">
                <div className="col-12 greeting-title-preview">
                    {props.title}
                </div>
                <div className="col-12">
                    {!props.isVideoGreeting && props.greetingContentBlocks.length !== 0 &&
                    greetingContentBlocksPreview
                    }
                    {props.isVideoGreeting &&
                    <GreetingVideoPreview video={props.video}/>}
                </div>
                <div className="col-12">
                    {i18next.t('greeting.greeting_preview.deliver')} {deliveryOptionText(props)}
                </div>
                {!props.editReceivers &&
                <div className="col-12">
                    <ReceiverList receiverList={props.receiverList} fromGreetingPreview={true}/>
                </div>}
            </div>
        </div>
    );
}

export default GreetingPreview;