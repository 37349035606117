import React, {useEffect, useState} from 'react';
import i18next from '../../languages/no/translation';
import {Prompt, useHistory} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {
    selectJwt,
    selectUserId,
    setAuthentication,
    setJwt,
    setLogout,
    setUserId
} from "../../store/slices/authenticationSlice";
import UserTermsModal from "./UserTermModal";
import {setBackgroundImage} from "../../store/slices/styleSlice";

const axios = require('axios');

function User(props: any) {

    let register_user_ajax_lock = false;
    let delete_user_ajax_lock = false;

    const dispatch = useAppDispatch();

    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const [apiUrl, setApiUrl] = useState("/user/new");
    const [apiHeaders, setApiHeaders] = useState({"Content-Type": "application/json"});
    const [userDeleted, setUserDeleted] = useState(false);
    const [formChanged, setFormChanged] = useState(false);

    const authentication = useAppSelector(selectJwt);
    const user = useAppSelector(selectUserId);

    let [userObj, setUserObj] = useState<any>(null);

    const history = useHistory();
    const registerUser = (e: any) => {
        e.preventDefault();
        if (!register_user_ajax_lock) {
            register_user_ajax_lock = true;
            let userRegistrationForm = document.getElementById("user-registration-form") as HTMLFormElement;
            let formData = new FormData(userRegistrationForm);
            const userObject = {} as any;
            formData.forEach((value, key) => (userObject[key] = value));
            setErrorMessage("");
            setFormChanged(false);
            axios({
                url: apiUrl,
                method: "post",
                headers: apiHeaders,
                data: userObject
            })
                .then(function (response: any) {
                    const obj = response.data;
                    if (!userObj && obj.user_id !== undefined) {
                        setSuccessMessage(i18next.t('user.registration_form.success_message_register'));
                        setTimeout(function () {
                            history.push("/login");
                        }, 5000);
                    }
                    if (userObj && authentication.authenticated === true && obj.user_id !== undefined) {
                        setSuccessMessage(i18next.t('user.registration_form.success_message_update_profile'));
                    }
                    register_user_ajax_lock = false;
                })
                .catch(function (error: any) {
                    setErrorMessage(error.response.data.error);
                    register_user_ajax_lock = false;
                });
        }
    }

    function getUser() {
        if (user !== null && authentication.authenticated === true) {
            // @ts-ignore
            if (!isNaN(user.user_id) && user.user_id !== null) {
                // @ts-ignore
                axios({
                    url: "/user/" + user.user_id,
                    method: "get",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": authentication.jwt
                    }
                })
                    .then(function (response: any) {
                        const obj = response.data;
                        setUserObj(obj);
                        setApiUrl("/user/" + user.user_id + "/edit");
                        let apiHeadersObj = {
                            "Content-Type": "application/json",
                            "Authorization": authentication.jwt
                        }
                        setApiHeaders(apiHeadersObj);
                    })
                    .catch(function (error: any) {

                    });
            }
        }
    }

    const deleteUser = (e: any) => {
        e.preventDefault();
        if (!delete_user_ajax_lock) {
            delete_user_ajax_lock = true;
            setErrorMessage("");
            // @ts-ignore
            if (!isNaN(user.user_id) && user.user_id !== null) {
                if (!window.confirm(i18next.t('user.registration_form.delete_confirm_message'))) {
                    return false;
                }
                // @ts-ignore
                axios({
                    url: "/user/" + user.user_id + "/delete",
                    method: "get",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": authentication.jwt
                    }
                })
                    .then(function (response: any) {
                        const obj = response.data;
                        if (obj.user_id !== undefined) {
                            setSuccessMessage(i18next.t('user.registration_form.success_message_delete'));
                            setUserDeleted(true);
                            localStorage.removeItem('token');
                            localStorage.removeItem('user_id');
                            localStorage.removeItem('authenticated');
                            dispatch(setLogout());
                            history.push("/");
                        }
                        delete_user_ajax_lock = false;
                    })
                    .catch(function (error: any) {
                        setErrorMessage(error.response.data.error);
                        delete_user_ajax_lock = false;
                    });
            }
        }
    }

    const enableUserTermCheckbox = (enable: boolean) => {
        if (enable) {
            // @ts-ignore
            document.getElementById("terms").disabled = false;
        }
    }

    useEffect(() => {
        dispatch(setBackgroundImage('/images/clouds.jpg'));
        getUser();
    }, []);

    if (props.fromGreeting) {
        return (
            <div className="container">
                <div className="content-wrapper">
                    <div className="row my-3">
                        <div className="form-group col-md-6">
                            <label
                                htmlFor="first_name">{i18next.t('user.registration_form.fields.first_name.label')}</label>
                            <input type="text" className="form-control" name="first_name"
                                   defaultValue={userObj && userObj.first_name}
                                   placeholder={i18next.t('user.registration_form.fields.first_name.placeholder')}
                                   required/>
                        </div>
                        <div className="form-group col-md-6">
                            <label
                                htmlFor="last_name">{i18next.t('user.registration_form.fields.last_name.label')}</label>
                            <input type="text" className="form-control" name="last_name"
                                   defaultValue={userObj && userObj.last_name}
                                   placeholder={i18next.t('user.registration_form.fields.last_name.placeholder')}
                                   required/>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="form-group col-md-12">
                            <label
                                htmlFor="address">{i18next.t('user.registration_form.fields.address.label')}</label>
                            <input type="text" className="form-control" name="address"
                                   defaultValue={userObj && userObj.address}
                                   placeholder={i18next.t('user.registration_form.fields.address.placeholder')}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="form-group col-md-6">
                            <label htmlFor="zip">{i18next.t('user.registration_form.fields.zip.label')}</label>
                            <input type="text" className="form-control" name="zip" defaultValue={userObj && userObj.zip}
                                   placeholder={i18next.t('user.registration_form.fields.zip.placeholder')}/>
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="city">{i18next.t('user.registration_form.fields.city.label')}</label>
                            <input type="text" className="form-control" name="city"
                                   defaultValue={userObj && userObj.city}
                                   placeholder={i18next.t('user.registration_form.fields.city.placeholder')}/>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="form-group col-md-6">
                            <label
                                htmlFor="date_of_birth">{i18next.t('user.registration_form.fields.date_of_birth.label')}</label>
                            <input type="date" className="form-control" name="date_of_birth"
                                   defaultValue={userObj && userObj.date_of_birth}
                                   placeholder={i18next.t('user.registration_form.fields.date_of_birth.placeholder')}/>
                        </div>
                        <div className="form-group col-md-6">
                            <label
                                htmlFor="social_security_number">{i18next.t('user.registration_form.fields.social_security_number.label')}</label>
                            <input type="number" className="form-control" name="social_security_number"
                                   defaultValue={userObj && userObj.social_security_number}
                                   placeholder={i18next.t('user.registration_form.fields.social_security_number.placeholder')}/>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="form-group col-md-6">
                            <label htmlFor="country">{i18next.t('user.registration_form.fields.country.label')}</label>
                            <input type="text" className="form-control" name="country"
                                   defaultValue={userObj && userObj.country}
                                   placeholder={i18next.t('user.registration_form.fields.country.placeholder')}/>
                        </div>
                        <div className="form-group col-md-6">
                            <label
                                htmlFor="phone_number">{i18next.t('user.registration_form.fields.phone_number.label')}</label>
                            <input type="tel" className="form-control" name="phone_number"
                                   defaultValue={userObj && userObj.phone_number}
                                   placeholder={i18next.t('user.registration_form.fields.phone_number.placeholder')}/>
                        </div>
                    </div>
                </div>
            </div>

        );
    } else {
        return (
            <div className="container">
                <Prompt when={formChanged} message={i18next.t('user.registration_form.form_has_changed')}/>
                <form id="user-registration-form" method="post" onSubmit={registerUser}>
                    <div className="content-wrapper">
                        {!userDeleted &&
                        <>
                            <div className="row my-3">
                                <div className="form-group col-md-6">
                                    <label
                                        htmlFor="first_name">{i18next.t('user.registration_form.fields.first_name.label')}</label>
                                    <input onChange={() => setFormChanged(true)} type="text" className="form-control"
                                           name="first_name"
                                           defaultValue={userObj && userObj.first_name}
                                           placeholder={i18next.t('user.registration_form.fields.first_name.placeholder')}
                                           required/>
                                </div>
                                <div className="form-group col-md-6">
                                    <label
                                        htmlFor="last_name">{i18next.t('user.registration_form.fields.last_name.label')}</label>
                                    <input onChange={() => setFormChanged(true)} type="text" className="form-control"
                                           name="last_name"
                                           defaultValue={userObj && userObj.last_name}
                                           placeholder={i18next.t('user.registration_form.fields.last_name.placeholder')}
                                           required/>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="form-group col-md-12">
                                    <label
                                        htmlFor="address">{i18next.t('user.registration_form.fields.address.label')}</label>
                                    <input onChange={() => setFormChanged(true)} type="text" className="form-control"
                                           name="address"
                                           defaultValue={userObj && userObj.address}
                                           placeholder={i18next.t('user.registration_form.fields.address.placeholder')}
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="form-group col-md-6">
                                    <label htmlFor="zip">{i18next.t('user.registration_form.fields.zip.label')}</label>
                                    <input onChange={() => setFormChanged(true)} type="text" className="form-control"
                                           name="zip"
                                           defaultValue={userObj && userObj.zip}
                                           placeholder={i18next.t('user.registration_form.fields.zip.placeholder')}/>
                                </div>
                                <div className="form-group col-md-6">
                                    <label
                                        htmlFor="city">{i18next.t('user.registration_form.fields.city.label')}</label>
                                    <input onChange={() => setFormChanged(true)} type="text" className="form-control"
                                           name="city"
                                           defaultValue={userObj && userObj.city}
                                           placeholder={i18next.t('user.registration_form.fields.city.placeholder')}/>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="form-group col-md-6">
                                    <label
                                        htmlFor="date_of_birth">{i18next.t('user.registration_form.fields.date_of_birth.label')}</label>
                                    <input onChange={() => setFormChanged(true)} type="date" className="form-control"
                                           name="date_of_birth"
                                           defaultValue={userObj && userObj.date_of_birth}
                                           placeholder={i18next.t('user.registration_form.fields.date_of_birth.placeholder')}/>
                                </div>
                                <div className="form-group col-md-6">
                                    <label
                                        htmlFor="social_security_number">{i18next.t('user.registration_form.fields.social_security_number.label')}</label>
                                    <input onChange={() => setFormChanged(true)} type="number" className="form-control"
                                           name="social_security_number"
                                           defaultValue={userObj && userObj.social_security_number}
                                           placeholder={i18next.t('user.registration_form.fields.social_security_number.placeholder')}/>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="form-group col-md-6">
                                    <label
                                        htmlFor="country">{i18next.t('user.registration_form.fields.country.label')}</label>
                                    <input onChange={() => setFormChanged(true)} type="text" className="form-control"
                                           name="country"
                                           defaultValue={userObj && userObj.country}
                                           placeholder={i18next.t('user.registration_form.fields.country.placeholder')}/>
                                </div>
                                <div className="form-group col-md-6">
                                    <label
                                        htmlFor="phone_number">{i18next.t('user.registration_form.fields.phone_number.label')}</label>
                                    <input onChange={() => setFormChanged(true)} type="tel" className="form-control"
                                           name="phone_number"
                                           defaultValue={userObj && userObj.phone_number}
                                           placeholder={i18next.t('user.registration_form.fields.phone_number.placeholder')}/>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="form-group col-md-12">
                                    <label
                                        htmlFor="email">{i18next.t('user.registration_form.fields.email.label')}</label>
                                    <input onChange={() => setFormChanged(true)} type="email" className="form-control"
                                           name="email"
                                           defaultValue={userObj && userObj.email}
                                           placeholder={i18next.t('user.registration_form.fields.email.placeholder')}
                                           required/>
                                </div>
                            </div>
                        </>}
                        {!userObj && !userDeleted &&
                        <div className="row mb-3">
                            <div className="form-group col-md-6">
                                <label
                                    htmlFor="password">{i18next.t('user.registration_form.fields.password.label')}</label>
                                <input type="password" className="form-control" name="password"
                                       placeholder={i18next.t('user.registration_form.fields.password.placeholder')}/>
                            </div>
                            <div className="form-group col-md-6">
                                <label
                                    htmlFor="repeat_password">{i18next.t('user.registration_form.fields.repeat_password.label')}</label>
                                <input type="password" className="form-control" name="repeat_password"
                                       placeholder={i18next.t('user.registration_form.fields.repeat_password.placeholder')}/>
                            </div>
                        </div>}
                    </div>
                    {!userObj &&
                    <div className="row mb-3 text-start">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="terms" name="terms" disabled
                                   required/>
                            <label className="form-check-label">
                                <button type="button" className="btn btn-link btn-link-white" data-bs-toggle="modal"
                                        data-bs-target="#user-term-modal">{i18next.t('user.registration_form.fields.terms.label')}</button>
                            </label>
                        </div>
                    </div>}
                    {errorMessage !== "" &&
                    <div className="alert alert-danger" role="alert">
                        {errorMessage}
                    </div>}
                    {successMessage !== "" &&
                    <div className="alert alert-success" role="alert">
                        {successMessage}
                    </div>}
                    {!userDeleted &&
                    <button type="submit" id="user-registration-form-button" className="btn btn-primary">
                        {userObj === null && i18next.t('user.registration_form.button_register')}
                        {userObj && i18next.t('user.registration_form.button_update')}
                    </button>}
                    {userObj && !userDeleted &&
                    <button onClick={deleteUser} type="button" id="user-delete-button"
                            className="btn btn-outline-danger float-end">
                        {i18next.t('user.registration_form.button_delete')}
                    </button>}
                    <UserTermsModal enableUserTermCheckbox={(enable: boolean) => enableUserTermCheckbox(enable)}/>
                </form>
            </div>
        );
    }
}

export default User;