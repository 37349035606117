import React from 'react';
import {Link} from "react-router-dom";
import i18next from "../../languages/no/translation";

function UserMenu(props: any) {

    return (
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
                <Link onClick={props.toggle} className="nav-link" to="/profile">{i18next.t('menu.profile')}</Link>
            </li>
            <li className="nav-item">
                <Link onClick={props.toggle} className="nav-link" to="/receivers">{i18next.t('menu.receiver')}</Link>
            </li>
            <li className="nav-item">
                <Link onClick={props.toggle} className="nav-link" to="/greetings">{i18next.t('menu.greeting')}</Link>
            </li>
            <li className="nav-item">
                <Link onClick={props.toggle} className="nav-link" to="/prepp-form">{i18next.t('menu.prepp_form')}</Link>
            </li>
            <li className="nav-item">
                <Link onClick={props.toggle} className="nav-link" to="/logout">{i18next.t('menu.logout')}</Link>
            </li>
        </ul>
    );
}

export default UserMenu;