import React, {useEffect, useState} from 'react';
import axios from "axios";

function About() {

    const [article, setArticle] = useState("");
    const [title, setTitle] = useState("");

    function getArticle() {
        // @ts-ignore
        axios({
            url: "/about",
            method: "get",
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(function (response: any) {
                const obj = response.data;
                if (obj.article !== undefined) {
                    setArticle(obj.article);
                }
                if (obj.title !== undefined) {
                    setTitle(obj.title);
                }
            })
            .catch(function (error: any) {

            });
    }


    useEffect(() => {
        getArticle();
    }, []);

    return (
        <div className="container">
            <div className="content-wrapper">
                <div className="row my-3">
                    <div className="col-12">
                        {title &&
                        title}
                    </div>
                    {article &&
                    <div dangerouslySetInnerHTML={{__html:article}} className="col-12">
                    </div>}
                </div>
            </div>
        </div>
    );
}

export default About;