import React from 'react';
import {Link} from "react-router-dom";
import i18next from "../../languages/no/translation";

function Footer() {

    if (window.location.pathname === "/") {
        return null;
    } else {
        return (
            <div id="footer" className="container mt-auto mb-2">
                <div className="row">
                    <div className="col-12 text-center">
                        <ul className="list-unstyled">
                            <li>
                                <Link to="/privacy" className="site-link">{i18next.t('footer.links.privacy')}</Link>
                            </li>
                            <li>
                                <Link to="/about" className="site-link">{i18next.t('footer.links.about')}</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-center">
                        {i18next.t('footer.text')}
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;