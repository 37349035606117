import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import 'material-icons/iconfont/material-icons.scss';
import i18next from "../../languages/no/translation";
import {useAppSelector} from "../../store/hooks";
import {selectJwt, selectUserId} from "../../store/slices/authenticationSlice";
import ReceiverListDetail from './ReceiverListDetail';
import GreetingContentBlockImage from "../greeting/GreetingContentBlockImage";

function ReceiverList(props: any) {

    const authentication = useAppSelector(selectJwt);
    const user = useAppSelector(selectUserId);

    let [receivers, setReceivers] = useState<any[]>([]);
    let [apiFinished, setApiFinished] = useState(false);

    function getReceivers() {
        // @ts-ignore
        axios({
            url: "/user/" + user.user_id + "/receivers",
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": authentication.jwt
            }
        })
            .then(function (response: any) {
                const obj = response.data;
                setReceivers(obj.receivers);
                setApiFinished(true);
            })
            .catch(function (error: any) {

            });
    }

    useEffect(() => {
        getReceivers()
    }, []);

    const receiverInGreetingPreview = receivers.map((receiver, index: number) => {
        if (props.receiverList) {
            let receiverIsInGreeting = false;
            for (let i = 0; i < props.receiverList.length; i++) {
                if (props.receiverList[i] == receiver.receiver_id) {
                    receiverIsInGreeting = true;
                }
            }
            if (receiverIsInGreeting) {
                return (<ReceiverListDetail receiverList={props.receiverList} receiver={receiver}
                                            fromGreetingPreview={true}
                                            key={index}/>);
            }
        }
    });


    return (
        <div className="container">
            {apiFinished &&
            <div className="m-4">
                {!props.fromGreetingPreview &&
                <div className="row">
                    <div className="col-12">
                        <h1>{i18next.t('receiver.receiver_list.title')}</h1>
                    </div>
                    <div className="col-12">
                        {!props.fromGreeting &&
                        <Link to="/receiver/new"
                              className="btn btn-primary">{i18next.t('receiver.receiver_list.add_new_receiver_button')}</Link>}
                        {props.fromGreeting &&
                        <button onClick={props.addReceiverToggle} type={"button"} id="add-receiver-button"
                                className="btn btn-primary">{i18next.t('receiver.receiver_list.add_new_receiver_button')}</button>}
                    </div>
                </div>}
                <div>
                    <ul className="list-group my-3" id="receiver-list">
                        {props.fromGreeting && receivers.length !== 0 && receivers.map((receiver, index) => (
                            <ReceiverListDetail addReceiverToggle={props.addReceiverToggle} addGuardianToggle={(minorReceiverId:number)=>props.addGuardianToggle(minorReceiverId)} minorList={props.minorList} receiverList={props.receiverList} receiverGuardianList={props.receiverGuardianList} receiver={receiver}
                                                fromGreeting={true}
                                                addOrRemoveReceiver={props.addOrRemoveReceiver}
                                                addOrRemoveReceiverGuardian={props.addOrRemoveReceiverGuardian}
                                                key={index}/>
                        ))}
                        {props.fromGreetingPreview && receivers.length !== 0 &&
                        receiverInGreetingPreview
                        }
                        {!props.fromGreeting && !props.fromGreetingPreview && receivers.length !== 0 && receivers.map((receiver, index) => (
                            <ReceiverListDetail fromGreetingPreview={props.fromGreetingPreview} receiver={receiver}
                                                key={index}/>
                        ))}
                    </ul>
                    {receivers.length === 0 &&
                    <div>{i18next.t('receiver.receiver_list.no_receivers')}</div>}
                </div>
            </div>
            }
        </div>
    );
}

export default ReceiverList;