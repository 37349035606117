import React, {useEffect, useState} from 'react';
import i18next from "../../languages/no/translation";
import axios from "axios";

const UserTermModal = (props: any) => {

    let term_user_ajax_lock = false;

    const [userTerms, setUserTerms] = useState("");

    const getUserTermModal = () => {
        if (!term_user_ajax_lock) {
            term_user_ajax_lock = true;
            axios({
                url: "/terms",
                method: "get",
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(function (response: any) {
                    const obj = response.data;
                    if (obj.term !== undefined) {
                        setUserTerms(obj.term);
                        props.enableUserTermCheckbox(true);
                    }
                    term_user_ajax_lock = false;
                })
                .catch(function (error: any) {
                    term_user_ajax_lock = false;
                });
        }
    }


    useEffect(() => {
        getUserTermModal();
    }, []);


    return (
        <>
            <div className="modal fade" id="user-term-modal" tabIndex={-1} aria-labelledby="exampleModalLabel"
                 aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title"
                                id="exampleModalLabel">{i18next.t('user.registration_form.user_term_title')}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="col-12">
                                {userTerms}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Lukk
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

export default UserTermModal;