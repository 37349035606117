import React, {useEffect, useState} from 'react';
import i18next from "../../languages/no/translation";
import {useAppSelector, useAppDispatch} from '../../store/hooks';
import {
    selectAuthentication,
    setAuthentication,
    setJwt, setLogout,
    setUserId
} from "../../store/slices/authenticationSlice";
import {Redirect, Link} from "react-router-dom";
import {setBackgroundImage} from "../../store/slices/styleSlice";
import Utils from "../../Utils/Utils";

const axios = require('axios');

function Login() {

    let login_ajax_lock = false;

    const authentication = useAppSelector(selectAuthentication);
    const [errorMessage, setErrorMessage] = useState("");
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(setBackgroundImage('/images/man.jpg'));
    }, []);

    const loginUser = (e: any) => {
        e.preventDefault();
        if (!login_ajax_lock) {
            login_ajax_lock = true;
            let userLoginForm = document.getElementById("user-login-form") as HTMLFormElement;
            let formData = new FormData(userLoginForm);
            const loginObject = {} as any;
            formData.forEach((value, key) => (loginObject[key] = value));
            setErrorMessage("");
            axios({
                url: "/login",
                method: "post",
                headers: {"Content-Type": "application/json"},
                data: loginObject
            })
                .then(function (response: any) {
                    const obj = response.data;
                    if (obj.token !== undefined) {
                        localStorage.setItem('token', obj.token);
                        localStorage.setItem('user_id', obj.user_id);
                        localStorage.setItem('authenticated', 'true');
                        dispatch(setUserId(obj.user_id));
                        dispatch(setJwt(obj.token));
                        dispatch(setAuthentication(true));
                        //tokenRefresh();
                    }
                    login_ajax_lock = false;
                })
                .catch(function (error: any) {
                    setErrorMessage(error.response.data.error);
                    login_ajax_lock = false;
                });
        }
    }

    function tokenRefresh() {
        let token = getToken();
        if (token !== null) {
            let decryptedToken = Utils.parseJwt(token);
            if (decryptedToken !== null) {
                let tokenExpireDate = decryptedToken.exp;
                let dateNow = new Date;
                let unixTimestampNow = Math.floor(dateNow.getTime() / 1000);
                let tokenExpireCountdown = tokenExpireDate - unixTimestampNow;
                tokenExpireCountdown = tokenExpireCountdown - (10 * 60);
                tokenExpireCountdown = tokenExpireCountdown * 1000;
                setInterval(() => {
                    token = getToken();
                    if (token !== null) {
                        fetchNewToken(localStorage.getItem('user_id'), token);
                    }
                }, tokenExpireCountdown);
            }
        }
    }

    let getToken = () => {
        if (authentication.jwt) {
            return authentication.jwt;
        }
        if (localStorage.getItem('token')) {
            return localStorage.getItem('token');
        }
        return null;
    }

    function fetchNewToken(user_id: any, token: any) {
        axios({
            url: "/user/" + user_id + "/token/refresh",
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token
            }
        })
            .then(function (response: any) {
                const obj = response.data;
                if (obj.token !== undefined) {
                    localStorage.setItem('token', obj.token);
                    dispatch(setJwt(obj.token));
                    dispatch(setUserId(user_id));
                    dispatch(setAuthentication(true));
                } else {
                    localStorage.removeItem('token');
                    localStorage.removeItem('user_id');
                    localStorage.removeItem('authenticated');
                    document.location.href = '';
                }
            })
            .catch(function (error: any) {
                localStorage.removeItem('token');
                localStorage.removeItem('user_id');
                localStorage.removeItem('authenticated');
                dispatch(setLogout());
            });
    }

    return (
        <div className="container">
            <form id="user-login-form" method="post" onSubmit={loginUser}>
                <div className="content-wrapper">
                    <div className="row my-3">
                        <div className="form-group col-md-6">
                            <label htmlFor="email">{i18next.t('user.login_form.fields.email.label')}</label>
                            <input type="text" className="form-control" name="email"
                                   placeholder={i18next.t('user.login_form.fields.email.placeholder')} required/>
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="password">{i18next.t('user.login_form.fields.password.label')}</label>
                            <input type="password" className="form-control" name="password" 
                                   placeholder={i18next.t('user.login_form.fields.password.placeholder')} required/>
                        </div>
                    </div>
                </div>
                <div className="row my-3">
                    {errorMessage !== "" &&
                    <div className="alert alert-danger" role="alert">
                        {errorMessage}
                    </div>}
                    <div className="form-group col-md-12">
                        <button type="submit" id="user-login-form-button" className="btn btn-primary">
                            {i18next.t('user.login_form.button')}
                        </button>
                    </div>
                    <div className="form-group col-md-12">
                        <Link to="/new-password" className="site-link">Glemt passordet?</Link>
                    </div>
                </div>
                {authentication.authenticated &&
                <Redirect to="/greetings"/>}
            </form>
        </div>
    );
}

export default Login;