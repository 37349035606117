import React from 'react';
import {Redirect, Route} from "react-router-dom";
import {useAppSelector} from "../../store/hooks";
import {
    selectAuthentication
} from "../../store/slices/authenticationSlice";

// @ts-ignore
const ProtectedRoute = ({component: Comp, path, ...rest}) => {

    const authentication = useAppSelector(selectAuthentication);

    return (
        <Route
            path={path}
            {...rest}
            render={(props) => {
                return authentication.authenticated ? <Comp {...props} /> : <Redirect to="/login"/>;
            }}
        />
    );
};

export default ProtectedRoute;