import React, {useEffect, useState} from 'react';
import axios from "axios";
import i18next from "../../languages/no/translation";

function PreppForReceiver(props: any) {

    let view_prepp_ajax_lock = false;

    const token = props.match.params && props.match.params.token ? props.match.params.token : null;

    const [apiFinished, setApiFinished] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [burialPlace, setBurialPlace] = useState("");
    const [ashSpread, setAshSpread] = useState("");
    const [funeralAgency, setFuneralAgency] = useState("");
    const [funeralLocation, setFuneralLocation] = useState("");
    const [funeralPicture, setFuneralPicture] = useState("");
    const [burialClothes, setBurialClothes] = useState("");
    const [funeralVideo, setFuneralVideo] = useState("");
    const [funeralSpeech, setFuneralSpeech] = useState("");
    const [funeralSongsAndPoems, setFuneralSongsAndPoems] = useState("");
    const [donor, setDonor] = useState(false);
    const [donorResearch, setDonorResearch] = useState(false);
    const [codes, setCodes] = useState("");
    const [financeAndInsurance, setFinanceAndInsurance] = useState("");
    const [documents, setDocuments] = useState("");
    const [pets, setPets] = useState("");
    const [otherWishes, setOtherWishes] = useState("");
    const [funeralForm, setFuneralForm] = useState("");
    const [funeralCeremony, setFuneralCeremony] = useState("");
    const [organization, setOrganization] = useState("");
    const [writtenGreeting, setWrittenGreeting] = useState("");
    const [funeralFormText, setFuneralFormText] = useState("");
    const [funeralCeremonyText, setFuneralCeremonyText] = useState("");
    const [pdfDownloadLink, setPdfDownloadLink] = useState("");

    const preppFormValues = (value: any) => {
        if (value.burial_place) {
            setBurialPlace(value.burial_place);
        }
        if (value.ash_spread) {
            setAshSpread(value.ash_spread);
        }
        if (value.funeral_agency) {
            setFuneralAgency(value.funeral_agency);
        }
        if (value.funeral_location) {
            setFuneralLocation(value.funeral_location);
        }
        if (value.funeral_picture) {
            setFuneralPicture(value.funeral_picture);
        }
        if (value.burial_clothes) {
            setBurialClothes(value.burial_clothes);
        }
        if (value.funeral_video) {
            setFuneralVideo(value.funeral_video);
        }
        if (value.funeral_speech) {
            setFuneralSpeech(value.funeral_speech);
        }
        if (value.funeral_songs_and_poems) {
            setFuneralSongsAndPoems(value.funeral_songs_and_poems);
        }
        if (value.donor && value.donor == '1') {
            setDonor(true);
        }
        if (value.donor_research && value.donor_research == '1') {
            setDonorResearch(true);
        }
        if (value.codes) {
            setCodes(value.codes);
        }
        if (value.finance_and_insurance) {
            setFinanceAndInsurance(value.finance_and_insurance);
        }
        if (value.documents) {
            setDocuments(value.documents);
        }
        if (value.pets) {
            setPets(value.pets);
        }
        if (value.other_wishes) {
            setOtherWishes(value.other_wishes);
        }
        if (value.funeral_form) {
            setFuneralForm(value.funeral_form);
            getFuneralFormText(value.funeral_form);
        }
        if (value.funeral_ceremony) {
            setFuneralCeremony(value.funeral_ceremony);
            getFuneralCeremonyText(value.funeral_ceremony);
        }
        if (value.organization) {
            setOrganization(value.organization);
        }
        if (value.written_greeting) {
            setWrittenGreeting(value.written_greeting);
        }
    }

    function getPrepp() {
        setErrorMessage("");
        if (token !== null) {
            if (!view_prepp_ajax_lock) {
                view_prepp_ajax_lock = true;
                const dataObject = {} as any;
                dataObject['token'] = token;
                // @ts-ignore
                axios({
                    url: "/prepp_form/show_to_receiver",
                    method: "post",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    data: dataObject,
                })
                    .then(function (response: any) {
                        const obj = response.data;
                        obj.prepp_form.forEach(preppFormValues);
                        if (obj.pdf_download_link) {
                            setPdfDownloadLink(obj.pdf_download_link);
                        }
                        setApiFinished(true);
                        view_prepp_ajax_lock = false;
                    })
                    .catch(function (error: any) {
                        setErrorMessage(error.response.data.error);
                        view_prepp_ajax_lock = false;
                    });
            }
        }
    }

    const getFuneralFormText = (funeralForm: string) => {
        let funeralFormText = '';
        switch (funeralForm) {
            case 'coffin_grave':
                funeralFormText = i18next.t('prepp_form.fields.funeral_form.options.coffin_grave');
                break;
            case 'cremation_and_urn_grave':
                funeralFormText = i18next.t('prepp_form.fields.funeral_form.options.cremation_and_urn_grave');
                break;
            case 'cremation_and_name_memorial_grove':
                funeralFormText = i18next.t('prepp_form.fields.funeral_form.options.cremation_and_name_memorial_grove');
                break;
            case 'cremation_and_anonymous_memorial_grove':
                funeralFormText = i18next.t('prepp_form.fields.funeral_form.options.cremation_and_anonymous_memorial_grove');
                break;
            case 'cremation_and_ash_scattering':
                funeralFormText = i18next.t('prepp_form.fields.funeral_form.options.cremation_and_ash_scattering');
                break;
        }
        setFuneralFormText(funeralFormText);
    }

    const getFuneralCeremonyText = (funeralCeremony: string) => {
        let funeralCeremonyText = '';
        switch (funeralCeremony) {
            case 'traditional_church_ceremony':
                funeralCeremonyText = i18next.t('prepp_form.fields.funeral_ceremony.options.traditional_church_ceremony');
                break;
            case 'humanistic_ceremony':
                funeralCeremonyText = i18next.t('prepp_form.fields.funeral_ceremony.options.humanistic_ceremony');
                break;
            case 'personal_ceremony':
                funeralCeremonyText = i18next.t('prepp_form.fields.funeral_ceremony.options.personal_ceremony');
                break;
            case 'no_ceremony':
                funeralCeremonyText = i18next.t('prepp_form.fields.funeral_ceremony.options.no_ceremony');
                break;
        }
        setFuneralCeremonyText(funeralCeremonyText);
    }

    function fileNameFromUrl(url: string) {
        return url.substring(url.lastIndexOf('/') + 1);
    }

    useEffect(() => {
        getPrepp();
    }, []);


    return (
        <div className="container">
            <div className="content-wrapper">
                {apiFinished &&
                    <>
                        {funeralForm &&
                            <div className="row my-3">
                                <label>{i18next.t('prepp_form.fields.funeral_form.label')}</label>
                                <div className="col-12">
                                    {funeralFormText}
                                </div>
                            </div>}
                        {funeralCeremony &&
                            <div className="row my-3">
                                <label>{i18next.t('prepp_form.fields.funeral_ceremony.label')}</label>
                                <div className="col-12">
                                    {funeralCeremonyText}
                                </div>
                            </div>}
                        {burialPlace &&
                            <div className="row my-3">
                                <label>{i18next.t('prepp_form.fields.burial_place.label')}</label>
                                <div className="col-12">
                                    {burialPlace}
                                </div>
                            </div>}
                        {ashSpread &&
                            <div className="row my-3">
                                <label>{i18next.t('prepp_form.fields.ash_spread.label')}</label>
                                <div className="col-12">
                                    {ashSpread}
                                </div>
                            </div>}
                        {funeralAgency &&
                            <div className="row my-3">
                                <label>{i18next.t('prepp_form.fields.funeral_agency.label')}</label>
                                <div className="col-12">
                                    {funeralAgency}
                                </div>
                            </div>}
                        {funeralLocation &&
                            <div className="row my-3">
                                <label>{i18next.t('prepp_form.fields.funeral_location.label')}</label>
                                <div className="col-12">
                                    {funeralLocation}
                                </div>
                            </div>}
                        {funeralPicture &&
                            <div className="row my-3">
                                <label>{i18next.t('prepp_form.fields.funeral_picture.label')}</label>
                                <div className="row">
                                    <div className="col-6">
                            <span className="prepp-form-image-wrapper">
                                    <img src={funeralPicture}/>
                                    </span>
                                    </div>
                                </div>
                            </div>}
                        {funeralVideo &&
                            <div className="row my-3">
                                <label>{i18next.t('prepp_form.fields.funeral_video.label')}</label>
                                <div className="row">
                                    <div className="col-6">
                                        <video controls preload="metadata" className="prepp-form-video-wrapper">
                                            <source type="video/mp4" src={funeralVideo}/>
                                        </video>
                                    </div>
                                </div>
                            </div>}
                        {funeralSpeech &&
                            <div className="row my-3">
                                <label>{i18next.t('prepp_form.fields.funeral_speech.label')}</label>
                                <div className="col-12">
                                    <a href={funeralSpeech} target="_blank" className="btn btn-link pt-2">
                                        {fileNameFromUrl(funeralSpeech)}
                                    </a>
                                </div>
                            </div>}
                        {burialClothes &&
                            <div className="row my-3">
                                <label>{i18next.t('prepp_form.fields.burial_clothes.label')}</label>
                                <div className="col-12">
                                    {burialClothes}
                                </div>
                            </div>}
                        {funeralSongsAndPoems &&
                            <div className="row my-3">
                                <label>{i18next.t('prepp_form.fields.funeral_songs_and_poems.label')}</label>
                                <div className="col-12">
                                    {funeralSongsAndPoems}
                                </div>
                            </div>}
                        {organization &&
                            <div className="row my-3">
                                <label>{i18next.t('prepp_form.fields.organization.label')}</label>
                                <div className="col-12">
                                    {organization}
                                </div>
                            </div>}
                        {donor &&
                            <div className="row my-3">
                                <label>{i18next.t('prepp_form.fields.donor.label')}</label>
                                <div className="col-12">
                                    {i18next.t('prepp_form.fields.donor.yes')}
                                </div>
                            </div>}
                        {donorResearch &&
                            <div className="row my-3">
                                <label>{i18next.t('prepp_form.fields.donor_research.label')}</label>
                                <div className="col-12">
                                    {i18next.t('prepp_form.fields.donor.yes')}
                                </div>
                            </div>}
                        {codes &&
                            <div className="row my-3">
                                <label>{i18next.t('prepp_form.fields.codes.label')}</label>
                                <div className="col-12">
                                    {codes}
                                </div>
                            </div>}
                        {financeAndInsurance &&
                            <div className="row my-3">
                                <label>{i18next.t('prepp_form.fields.finance_and_insurance.label')}</label>
                                <div className="col-12">
                                    {financeAndInsurance}
                                </div>
                            </div>}
                        {documents &&
                            <div className="row my-3">
                                <label>{i18next.t('prepp_form.fields.documents.label')}</label>
                                <div className="col-12">
                                    <a href={documents} target="_blank" className="btn btn-link pt-0">
                                        {fileNameFromUrl(documents)}
                                    </a>
                                </div>
                            </div>}
                        {pets &&
                            <div className="row my-3">
                                <label>{i18next.t('prepp_form.fields.pets.label')}</label>
                                <div className="col-12">
                                    {pets}
                                </div>
                            </div>}
                        {otherWishes &&
                            <div className="row my-3">
                                <label>{i18next.t('prepp_form.fields.other_wishes.label')}</label>
                                <div className="col-12">
                                    {otherWishes}
                                </div>
                            </div>}
                        {writtenGreeting &&
                            <div className="row my-3">
                                <label>{i18next.t('prepp_form.fields.written_greeting.label')}</label>
                                <div className="col-12">
                                    {writtenGreeting}
                                </div>
                            </div>}
                        {pdfDownloadLink &&
                            <div className="col-12 mt-4">
                                <a href={pdfDownloadLink} target="_blank"
                                   className="btn btn-primary">{i18next.t('prepp_form.download_preppform_button')}</a>
                            </div>}
                        {(funeralPicture || funeralSpeech || documents) &&
                            <div className="col-12 mt-4">
                                {i18next.t('prepp_form.attachments')}
                            </div>
                        }
                        {funeralVideo &&
                            <div className="col-12 mt-4">
                                <a href={funeralVideo} target="_blank"
                                   className="btn btn-primary">{i18next.t('prepp_form.download_preppform_video_button')}</a>
                            </div>}
                        {funeralPicture &&
                            <div className="col-12 mt-4">
                                <a href={funeralPicture} target="_blank"
                                   className="btn btn-primary">{i18next.t('prepp_form.download_preppform_image_button')}</a>
                            </div>}
                        {funeralSpeech &&
                            <div className="col-12 mt-4">
                                <a href={funeralSpeech} target="_blank"
                                   className="btn btn-primary">{i18next.t('prepp_form.download_preppform_speech_button')}</a>
                            </div>}
                        {documents &&
                            <div className="col-12 mt-4">
                                <a href={documents} target="_blank"
                                   className="btn btn-primary">{i18next.t('prepp_form.download_preppform_document_button')}</a>
                            </div>}
                    </>}
                {errorMessage !== "" &&
                    <div className="alert alert-danger" role="alert">
                        {errorMessage}
                    </div>}
            </div>
        </div>
    );
}

export default PreppForReceiver;