import React, {useRef, useState} from 'react';
import 'material-icons/iconfont/material-icons.scss';
import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/table';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/content/default/content.min.css';
import {Editor} from '@tinymce/tinymce-react';

function GreetingContentBlockText(this: any, props: any) {

    const handleEditorChange = (content: any, editor: any) => {
        props.setGreetingContentBlockBody(content, props.listId);
        editor.selection.select(editor.getBody(), true);
        editor.selection.collapse(false);
        props.setFormChanged(true);
    }

    return (
        <div className="row my-3 border border-1 rounded p-3 block-wrapper" data-list-id={props.listId}>
            <div data-listorder={props.listOrder} className="col-12">
                <Editor
                    initialValue={props.greetingContentBlock && props.greetingContentBlock.body ? props.greetingContentBlock.body : ""}
                    init={{
                        skin: false,
                        branding: false,
                        content_css: false,
                        content_style: "body { font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,Cantarell,'Open Sans','Helvetica Neue',sans-serif; }",
                        height: 250,
                        menubar: false,
                        plugins: [
                            'lists advlist bold size style'
                        ],
                        fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
                        toolbar:
                            'fontsizeselect | bold | numlist bullist | forecolor | removeformat'
                    }}
                    onEditorChange={(c, e) => handleEditorChange(c, e)}
                />
                <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Fjern tekstblokk"
                        className="btn btn-light p-0 close" onClick={() => props.removeBlock(props.listId)}>
                    <span className="material-icons">close</span>
                </button>
                <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Flytt rekkefølge"
                        className="btn btn-light p-0 drag_indicator">
                    <span className="material-icons">drag_indicator</span>
                </button>
            </div>
        </div>
    );
}

export default GreetingContentBlockText;