import React from 'react';
import 'material-icons/iconfont/material-icons.scss';
import {Link} from "react-router-dom";

function GreetingListDetail(props: any) {

    const getLink = () => {
        if (props.greeting.has_greeting_video) {
            return `/greeting/${props.greeting.greeting_id}?video=1`;
        }
        return `/greeting/${props.greeting.greeting_id}`;
    }

    return (
        <Link to={getLink}
              className={`list-group-item list-group-item-action ${props.greeting.status ? 'list-group-item-success' : ''}`}
              key={'greeting-list-detail-' + props.greeting.greeting_id}>
            <span className="float-start" key={`title-${props.greeting.greeting_id}`}>
                {props.greeting.title}
            </span>
            {props.greeting.has_greeting_video &&
            <span className="float-end" key={`icon-${props.greeting.greeting_id}`}>
                 <span className="material-icons-outlined ml-4 mr-4">smart_display</span>
            </span>}
        </Link>
    );
}

export default GreetingListDetail;