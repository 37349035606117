import React from 'react';
import 'material-icons/iconfont/material-icons.scss';
import {Link} from "react-router-dom";

function ReceiverGuardianListDetail(props: any) {

    const addOrRemoveReceiverGuardian = (minorReceiverId: number, receiverGuardianId: number) => {
        let receiver = document.getElementById('receiver-id-' + minorReceiverId + '-guardian-' + receiverGuardianId);
        // @ts-ignore
        if (receiver.classList.contains('active')) {
            // @ts-ignore
            receiver.classList.remove('active');
        } else {
            // @ts-ignore
            receiver.classList.add('active');
        }
        props.addOrRemoveReceiverGuardian(minorReceiverId, receiverGuardianId);
    }

    const isReceiverGuardian = (minorReceiverId: number, receiverGuardianId: number) => {
        if (!props.receiverGuardianList) {
            return false;
        }
        for (let i = 0; i < props.receiverGuardianList.length; i++) {
            if (props.receiverGuardianList[i].receiver_id == minorReceiverId && props.receiverGuardianList[i].receiver_guardian_id == receiverGuardianId) {
                return ' active';
            }
        }
    }


    if (props.fromGreeting && props.fromGreeting == true) {
        return (
            <div onClick={() => addOrRemoveReceiverGuardian(props.minorReceiverId, props.receiver.receiver_id)}
                 className={`list-group-item list-group-item-action ${isReceiverGuardian(props.minorReceiverId, props.receiver.receiver_id)}`}
                 key={props.receiver.receiver_id}
                 id={`receiver-id-` + props.minorReceiverId + `-guardian-` + props.receiver.receiver_id}>
            <span className="float-start">
                {props.receiver.first_name} {props.receiver.last_name}
            </span>
            </div>
        );
    } else {
        if (props.fromGreetingPreview && props.fromGreetingPreview == true) {
            return (
                <div className="list-group-item"
                     key={props.receiver.receiver_id}
                     id={`receiver-id-` + props.receiver.receiver_id}>
                    <span className="float-start" key={'name' + props.receiver.receiver_id}>
                        {props.receiver.first_name} {props.receiver.last_name}
                    </span>
                </div>
            );
        } else {
            return (
                <Link to={`/receiver/${props.receiver.receiver_id}`} className="list-group-item list-group-item-action"
                      key={props.receiver.receiver_id}>
                    <span className="float-start">
                        {props.receiver.first_name} {props.receiver.last_name}
                    </span>
                </Link>
            );
        }
    }

}

export default ReceiverGuardianListDetail;