import React, {useState} from 'react';
import i18next from "../../languages/no/translation";
import {Redirect} from "react-router-dom";

const axios = require('axios');

function SetPassword(props: any) {

    let set_password_ajax_lock = false;

    const token = props.match.params && props.match.params.token ? props.match.params.token : null;

    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const setPassword = (e: any) => {
        e.preventDefault();
        if (!set_password_ajax_lock) {
            set_password_ajax_lock = true;
            let userLoginForm = document.getElementById("user-set-password-form") as HTMLFormElement;
            let formData = new FormData(userLoginForm);
            const userObject = {} as any;
            formData.forEach((value, key) => (userObject[key] = value));
            userObject.token = token;
            setErrorMessage("");
            axios({
                url: "/user/set_password",
                method: "post",
                headers: {"Content-Type": "application/json"},
                data: userObject
            })
                .then(function (response: any) {
                    const obj = response.data;
                    if (obj.user_id !== undefined) {
                        setSuccessMessage(i18next.t('user.set_password_form.success_message'));
                    }
                    set_password_ajax_lock = false;
                })
                .catch(function (error: any) {
                    console.log('cde' + error.response.data.error);
                    setErrorMessage(error.response.data.error);
                    set_password_ajax_lock = false;
                });
        }
    }

    return (
        <div className="container">
            <form id="user-set-password-form" method="post" onSubmit={setPassword}>
                <div className="content-wrapper">
                    <div className="row my-3">
                        <div className="form-group col-md-6">
                            <label
                                htmlFor="password">{i18next.t('user.set_password_form.fields.password.label')}</label>
                            <input type="password" className="form-control" name="password"
                                   placeholder={i18next.t('user.set_password_form.fields.password.placeholder')}
                                   required/>
                        </div>
                        <div className="form-group col-md-6">
                            <label
                                htmlFor="repeat_password">{i18next.t('user.set_password_form.fields.repeat_password.label')}</label>
                            <input type="password" className="form-control" name="repeat_password"
                                   placeholder={i18next.t('user.set_password_form.fields.repeat_password.placeholder')}
                                   required/>
                        </div>
                    </div>
                </div>
                <div className="row my-3">
                    {errorMessage !== "" &&
                    <div className="alert alert-danger" role="alert">
                        {errorMessage}
                    </div>}
                    <div className="form-group col-md-12">
                        <button type="submit" id="user-login-form-button" className="btn btn-primary">
                            {i18next.t('user.set_password_form.button')}
                        </button>
                    </div>
                </div>
                {successMessage !== "" &&
                    <div className="alert alert-success" role="alert">
                        {successMessage}
                    </div>}
            </form>
        </div>
    );
}

export default SetPassword;