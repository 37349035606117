import React, {useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {
    selectAuthentication,
    setAuthentication,
    setJwt,
    setUserId
} from "../../store/slices/authenticationSlice";
import UserMenu from "./UserMenu";
import DefaultMenu from "./DefaultMenu";
import {Link} from "react-router-dom";

function Header() {

    const authentication = useAppSelector(selectAuthentication);
    const dispatch = useAppDispatch();

    let checkAuthentication = () => {
        if (authentication.authenticated) {
            return true;
        }
        let token = localStorage.getItem('token');
        let user_id = localStorage.getItem('user_id');
        let authenticated = localStorage.getItem('authenticated');
        if (token && user_id && authenticated === 'true') {
            dispatch(setJwt(token));
            dispatch(setUserId(Number(user_id)));
            dispatch(setAuthentication(authenticated === 'true'));
            return true;
        }
        localStorage.removeItem('token');
        localStorage.removeItem('user_id');
        localStorage.removeItem('authenticated');
        return false;
    }

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    return (
        <nav className="navbar navbar-dark ">
            <div className="container">
                {checkAuthentication() &&
                <Link className="navbar-brand" to="/greetings"><img src="/logo.svg" alt="Prepp"/></Link>
                }
                {!checkAuthentication() &&
                <Link className="navbar-brand" to="/"><img src="/logo.svg" alt="Prepp"/></Link>
                }
                <button onClick={toggle} className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={`collapse navbar-collapse ${isOpen ? ' show' : ''}`} id="navbarSupportedContent">
                    {checkAuthentication() &&
                    <UserMenu toggle={toggle}/>
                    }
                    {!checkAuthentication() &&
                    <DefaultMenu toggle={toggle}/>
                    }
                </div>
            </div>
        </nav>
    );
}

export default Header;