import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState, AppThunk} from '../store';
import {incrementByAmount, selectCount} from "../../features/counter/counterSlice";

//import { fetchCount } from './counterAPI';

export interface UserState {
    authenticated: boolean;
    user_id: number | null,
    jwt: string | null,
    status: 'idle' | 'loading';
}

const initialState: UserState = {
    authenticated: false,
    user_id: null,
    jwt: null,
    status: 'idle',
};

/*export const incrementAsync = createAsyncThunk(
    'counter/fetchCount',
    async (amount: number) => {
        const response = await fetchCount(amount);
        return response.data;
    }
);*/

export const authenticationSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setAuthentication: (state, action: PayloadAction<boolean>) => {
            state.authenticated = action.payload;
        },
        setUserId: (state, action: PayloadAction<number>) => {
            state.user_id = action.payload;
        },
        setJwt: (state, action: PayloadAction<string>) => {
            state.jwt = action.payload;
        },
        setLogout: (state) => {
            state.authenticated = false;
            state.user_id = null;
            state.jwt = null;
        },
    },
    /*extraReducers: (builder) => {
        builder
            .addCase(incrementAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(incrementAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.value += action.payload;
            });
    },*/
});

export const {setAuthentication, setUserId, setJwt, setLogout} = authenticationSlice.actions;
export const selectUserId = (state: RootState) => state.user_id;
export const selectJwt = (state: RootState) => state.jwt;
export const selectAuthentication = (state: RootState) => state.authenticated;

export const refreshToken = (): AppThunk => (
    dispatch
) => {
    //sjekk om token finnes og om token dato er i ferd med å gå ut
    //forny token
    /*if (token) {
        dispatch(setJwt(ny_token)); //token fra api etter ajax/promise
    }*/
};

export default authenticationSlice.reducer;
