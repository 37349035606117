import React from 'react';
import {Link} from "react-router-dom";
import i18next from "../../languages/no/translation";

function DefaultMenu(props: any) {

    return (
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
                <Link onClick={props.toggle} className="nav-link" to="/register">{i18next.t('menu.register')}</Link>
            </li>
            <li className="nav-item">
                <Link onClick={props.toggle} className="nav-link" to="/login">{i18next.t('menu.login')}</Link>
            </li>
        </ul>
    );
}

export default DefaultMenu;