import React from 'react';
import 'material-icons/iconfont/material-icons.scss';
import {Link} from "react-router-dom";
import i18next from "../../languages/no/translation";
import ReceiverGuardianList from "./ReceiverGuardianList";

function ReceiverListDetail(props: any) {

    const addOrRemoveReceiver = (receiverId: number, minor: boolean) => {
        let receiver = document.getElementById('receiver-id-' + receiverId);
        // @ts-ignore
        if (receiver.classList.contains('active')) {
            // @ts-ignore
            receiver.classList.remove('active');
        } else {
            // @ts-ignore
            receiver.classList.add('active');
        }
        props.addOrRemoveReceiver(receiverId, minor);
    }

    const isReceiver = (receiverId: number) => {
        if (!props.receiverList) {
            return false;
        }
        for (let i = 0; i < props.receiverList.length; i++) {
            if (props.receiverList[i] == receiverId) {
                return ' active';
            }
        }
    }

    const isMinor = (receiverId: number) => {
        if (!props.minorList) {
            return false;
        }
        for (let i = 0; i < props.minorList.length; i++) {
            if (props.minorList[i] == receiverId) {
                return true;
            }
        }
    }

    if (props.fromGreeting && props.fromGreeting == true) {
        return (
            <>
                <div onClick={() => addOrRemoveReceiver(props.receiver.receiver_id, props.receiver.minor)}
                     className={`list-group-item list-group-item-action ${isReceiver(props.receiver.receiver_id)}`}
                     key={props.receiver.receiver_id}
                     id={`receiver-id-` + props.receiver.receiver_id}>
            <span className="float-start">
                {props.receiver.first_name} {props.receiver.last_name}
            </span>
                </div>
                {isMinor(props.receiver.receiver_id) &&
                    <ReceiverGuardianList minorReceiverId={props.receiver.receiver_id} minorList={props.minorList} receiverList={props.receiverList} receiverGuardianList={props.receiverGuardianList} fromGreeting={true}
                                          addReceiverToggle={props.addReceiverToggle} addGuardianToggle={(minorReceiverId:number)=>props.addGuardianToggle(minorReceiverId)}
                                          addOrRemoveReceiver={(receiverId: number, minor: boolean) => addOrRemoveReceiver(receiverId, minor)}
                                          addOrRemoveReceiverGuardian={(minorReceiverId: number, receiverGuardianId: number) => props.addOrRemoveReceiverGuardian(minorReceiverId, receiverGuardianId)}/>
                }
            </>
        );
    } else {
        if (props.fromGreetingPreview && props.fromGreetingPreview == true) {
            return (
                <div className="list-group-item"
                     key={props.receiver.receiver_id}
                     id={`receiver-id-` + props.receiver.receiver_id}>
                    <span className="float-start" key={'name' + props.receiver.receiver_id}>
                        {props.receiver.first_name} {props.receiver.last_name}
                    </span>
                </div>
            );
        } else {
            return (
                <Link to={`/receiver/${props.receiver.receiver_id}`} className="list-group-item list-group-item-action"
                      key={props.receiver.receiver_id}>
                    <span className="float-start">
                        {props.receiver.first_name} {props.receiver.last_name}
                    </span>
                </Link>
            );
        }
    }

}

export default ReceiverListDetail;