import React from 'react';
import {Redirect} from "react-router-dom";
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {
    selectJwt,
    selectUserId,
    setLogout
} from "../../store/slices/authenticationSlice";
import axios from "axios";

function Logout() {

    const authentication = useAppSelector(selectJwt);
    const user = useAppSelector(selectUserId);
    const dispatch = useAppDispatch();

    // @ts-ignore
    axios({
        url: "/user/" + user.user_id + "/logout",
        method: "get",
        headers: {
            "Content-Type": "application/json",
            "Authorization": authentication.jwt
        }
    })
        .then(function (response: any) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_id');
            localStorage.removeItem('authenticated');
            dispatch(setLogout());
        })
        .catch(function (error: any) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_id');
            localStorage.removeItem('authenticated');
            dispatch(setLogout());
        });

    return (
        <Redirect to="/login"/>
    );
}

export default Logout;