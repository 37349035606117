import React from 'react';
import 'material-icons/iconfont/material-icons.scss';

function GreetingContentBlockTextPreview(props: any) {

    return (
        <div className="m-4">
            <div className="row">
                <div dangerouslySetInnerHTML={{__html:props.greetingContentBlock.body}} className="col-12 greeting-content-block-wrapper">
                </div>
            </div>
        </div>
    );
}

export default GreetingContentBlockTextPreview;