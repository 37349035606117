import React, {useRef, useState} from 'react';
import 'material-icons/iconfont/material-icons.scss';

function GreetingContentBlockImage(props: any) {

    function previewImage(event: any) {
        const file = event.target.files[0];
        let imagePreview = document.getElementById('image_preview-' + props.listId);
        // @ts-ignore
        imagePreview.src = URL.createObjectURL(file);
        let imageFile = event.target.files[0];
        let fileReader = new FileReader();
        fileReader.onload = function (fileLoadedEvent) {
            // @ts-ignore
            let srcData = fileLoadedEvent.target.result;
            props.setGreetingContentBlockImage(srcData, props.listId);
        }
        fileReader.readAsDataURL(imageFile);
        props.setFormChanged(true);
    }

    const inputImage = useRef(null);
    const onButtonClick = () => {
        // @ts-ignore
        inputImage.current.click();
    };

    function updateBody() {
        // @ts-ignore
        let body = document.getElementById("body-" + props.listId).value;
        props.setGreetingContentBlockBody(body, props.listId);
        props.setFormChanged(true);
    }

    return (
        <div className="row my-3 border border-1 rounded p-3 block-wrapper" data-list-id={props.listId}>
            <div className="col-12">
                <input ref={inputImage} className="d-none" accept="image/*" type="file" id="image"
                       onChange={previewImage}/>
                <img id={`image_preview-` + props.listId}
                     src={props.greetingContentBlock && props.greetingContentBlock.image ? props.greetingContentBlock.image : ""}
                     alt=""/>
                <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Fjern tekstblokk"
                        className="btn btn-light p-0 close" onClick={() => props.removeBlock(props.listId)}>
                    <span className="material-icons">close</span>
                </button>
                <span className="drag_indicator material-icons" data-bs-toggle="tooltip" data-bs-placement="top"
                      title="Flytt rekkefølge">drag_indicator</span>
            </div>
            <div className="col-12">
                <button onClick={onButtonClick} type="button" className="btn btn-primary mt-2">Velg bilde</button>
            </div>
            <div className="col-12">
                <input id={`body-` + props.listId} onChange={updateBody} className="form-control d-block mt-2"
                       type="text"
                       placeholder="Bildetekst"
                       defaultValue={props.greetingContentBlock && props.greetingContentBlock.body}/>
            </div>
        </div>
    );
}

export default GreetingContentBlockImage;