import React, {useEffect, useRef, useState} from 'react';
import i18next from "../../languages/no/translation";
import {Prompt, useHistory} from "react-router-dom";
import axios from "axios";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {selectJwt, selectUserId} from "../../store/slices/authenticationSlice";
import ReceiverList from "../receiver/ReceiverList";
import Receiver from "../receiver/Receiver";
import UserTermsModal from "../user/UserTermModal";

function PreppForm() {

    let register_prepp_form_ajax_lock = false;
    let delete_file_prepp_form_ajax_lock = false;

    const authentication = useAppSelector(selectJwt);
    const user = useAppSelector(selectUserId);
    const [apiFinished, setApiFinished] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [formChanged, setFormChanged] = useState(false);

    const [socialSecurityNumber, setSocialSecurityNumber] = useState("");
    const [burialPlace, setBurialPlace] = useState("");
    const [ashSpread, setAshSpread] = useState("");
    const [funeralAgency, setFuneralAgency] = useState("");
    const [funeralLocation, setFuneralLocation] = useState("");
    const [funeralPicture, setFuneralPicture] = useState("");
    const [burialClothes, setBurialClothes] = useState("");
    const [funeralVideo, setFuneralVideo] = useState("");
    const [funeralSpeech, setFuneralSpeech] = useState("");
    const [funeralSongsAndPoems, setFuneralSongsAndPoems] = useState("");
    const [donor, setDonor] = useState(false);
    const [donorResearch, setDonorResearch] = useState(false);
    const [codes, setCodes] = useState("");
    const [financeAndInsurance, setFinanceAndInsurance] = useState("");
    const [documents, setDocuments] = useState("");
    const [pets, setPets] = useState("");
    const [otherWishes, setOtherWishes] = useState("");
    const [funeralForm, setFuneralForm] = useState("");
    const [funeralCeremony, setFuneralCeremony] = useState("");
    const [organization, setOrganization] = useState("");
    const [writtenGreeting, setWrittenGreeting] = useState("");
    const [funeralSpeechFileName, setFuneralSpeechFileName] = useState("");
    const [documentsFileName, setDocumentsFileName] = useState("");
    const [funeralPicturePreview, setFuneralPicturePreview] = useState(false);
    const [funeralVideoPreview, setFuneralVideoPreview] = useState(false);
    const [published, setPublished] = useState(false);
    const [approveTerm, setApproveTerm] = useState(false);

    const preppFormValues = (value: any) => {
        if (value.social_security_number) {
            setSocialSecurityNumber(value.social_security_number);
        }
        if (value.burial_place) {
            setBurialPlace(value.burial_place);
        }
        if (value.ash_spread) {
            setAshSpread(value.ash_spread);
        }
        if (value.funeral_agency) {
            setFuneralAgency(value.funeral_agency);
        }
        if (value.funeral_location) {
            setFuneralLocation(value.funeral_location);
        }
        if (value.funeral_picture) {
            setFuneralPicture(value.funeral_picture);
        }
        if (value.burial_clothes) {
            setBurialClothes(value.burial_clothes);
        }
        if (value.funeral_video) {
            setFuneralVideo(value.funeral_video);
        }
        if (value.funeral_speech) {
            setFuneralSpeech(value.funeral_speech);
        }
        if (value.funeral_songs_and_poems) {
            setFuneralSongsAndPoems(value.funeral_songs_and_poems);
        }
        if (value.donor && value.donor == '1') {
            setDonor(true);
        }
        if (value.donor_research && value.donor_research == '1') {
            setDonorResearch(true);
        }
        if (value.codes) {
            setCodes(value.codes);
        }
        if (value.finance_and_insurance) {
            setFinanceAndInsurance(value.finance_and_insurance);
        }
        if (value.documents) {
            setDocuments(value.documents);
        }
        if (value.pets) {
            setPets(value.pets);
        }
        if (value.other_wishes) {
            setOtherWishes(value.other_wishes);
        }
        if (value.funeral_form) {
            setFuneralForm(value.funeral_form);
        }
        if (value.funeral_ceremony) {
            setFuneralCeremony(value.funeral_ceremony);
        }
        if (value.organization) {
            setOrganization(value.organization);
        }
        if (value.written_greeting) {
            setWrittenGreeting(value.written_greeting);
        }
        if (value.published) {
            setPublished(value.published);
        }
    }
    const history = useHistory();
    const registerPreppForm = (e: any) => {
        e.preventDefault();
        setFormChanged(false);
        if (!register_prepp_form_ajax_lock) {
            register_prepp_form_ajax_lock = true;
            let preppRegistrationForm = document.getElementById("prepp-form") as HTMLFormElement;
            let formData = new FormData(preppRegistrationForm);
            const preppFormObject = {} as any;
            formData.forEach((value, key) => (preppFormObject[key] = value));
            if (receiverList.length) {
                // @ts-ignore
                formData.append('receivers', receiverList);
            }
            setErrorMessage("");
            // @ts-ignore
            axios({
                url: "/user/" + user.user_id + "/prepp_form/new",
                method: "post",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "multipart/form-data",
                    "Authorization": authentication.jwt
                },
                data: formData
            })
                .then(function (response: any) {
                    const obj = response.data;
                    if (obj.user_id) {
                        setSuccessMessage(i18next.t('prepp_form.success_message_register'));
                        setTimeout(function () {
                            setSuccessMessage("");
                            window.location.reload();
                        }, 3000);
                    }
                    register_prepp_form_ajax_lock = false;
                })
                .catch(function (error: any) {
                    setErrorMessage(error.response.data.error);
                    register_prepp_form_ajax_lock = false;
                });
        }
    }

    const [receiverList, setReceiverList] = useState<any[]>([]);
    const addOrRemoveReceiver = (receiverId: number) => {
        let receiverListArray = [...receiverList];
        // @ts-ignore
        let receiverIndex = receiverListArray.indexOf(receiverId);
        if (receiverIndex < 0) {
            // @ts-ignore
            receiverListArray.push(receiverId);
        } else {
            receiverListArray.splice(receiverIndex, 1);
        }
        setReceiverList(receiverListArray);
    };

    let [addReceiver, setAddReceiver] = useState(false);

    const addReceiverToggle = () => {
        if (addReceiver) {
            setAddReceiver(false);
        } else {
            setAddReceiver(true);
        }
    }

    function formHasChanged() {
        setFormChanged(true);
    }

    function getPreppForm() {
        // @ts-ignore
        axios({
            url: "/user/" + user.user_id + "/prepp_form",
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": authentication.jwt
            }
        })
            .then(function (response: any) {
                const obj = response.data;
                obj.prepp_form.forEach(preppFormValues);
                setReceiverList(obj.receivers);
                setApiFinished(true);
                funeralFormCheck();
            })
            .catch(function (error: any) {

            });
    }

    function deleteFile(element: string) {

        if (!window.confirm(i18next.t('prepp_form.delete_document_confirmation_message'))) {
            return false;
        }
        let data = {element: element}
        if (!delete_file_prepp_form_ajax_lock) {
            delete_file_prepp_form_ajax_lock = true;
            // @ts-ignore
            axios({
                url: "/user/" + user.user_id + "/prepp_form/delete_file",
                method: "post",
                headers: {
                    "Accept": "application/json",
                    "Authorization": authentication.jwt
                },
                data: data
            })
                .then(function (response: any) {
                    const obj = response.data;
                    if (obj.element == 'funeral_picture') {
                        setFuneralPicture("");
                    }
                    if (obj.element == 'funeral_video') {
                        setFuneralVideo("");
                    }
                    if (obj.element == 'funeral_speech') {
                        setFuneralSpeech("");
                    }
                    if (obj.element == 'documents') {
                        setDocuments("");
                    }
                    delete_file_prepp_form_ajax_lock = false;
                })
                .catch(function (error: any) {
                    delete_file_prepp_form_ajax_lock = false;
                });
        }
        return false;
    }

    const inputFilePicture = useRef(null);
    const onButtonPictureClick = () => {
        // @ts-ignore
        inputFilePicture.current.click();
    };
    const inputFileVideo = useRef(null);
    const onButtonVideoClick = () => {
        // @ts-ignore
        inputFileVideo.current.click();
    };
    const inputFileFuneralSpeech = useRef(null);
    const onButtonFuneralSpeechClick = () => {
        // @ts-ignore
        inputFileFuneralSpeech.current.click();
    };
    const inputFileDocument = useRef(null);
    const onButtonDocumentClick = () => {
        // @ts-ignore
        inputFileDocument.current.click();
    };

    function previewImage(event: any) {
        const file = event.target.files[0];
        setFuneralPicture(URL.createObjectURL(file));
        setFuneralPicturePreview(true);
        setFormChanged(true);
    }

    function previewVideo(event: any) {
        let videoPreview = document.getElementById('video-preview');
        let file = event.target.files[0];
        let fileReader = new FileReader();
        fileReader.onload = function (fileLoadedEvent) {
            // @ts-ignore
            let srcData = fileLoadedEvent.target.result;
            // @ts-ignore
            setFuneralVideo(srcData);
            if (videoPreview !== null) {
                videoPreview.remove();
            }
            let source = document.createElement("source");
            if (typeof srcData === "string") {
                source.src = srcData;
            }
            source.id = "video-preview";
            source.type = "video/mp4";
            let element = document.getElementById("funeral-video-wrapper");
            // @ts-ignore
            element.appendChild(source);
        }
        fileReader.readAsDataURL(file);
        setFuneralVideoPreview(true);
        setFormChanged(true);
    }

    function previewFuneralSpeech(event: any) {
        let file = event.target.files[0];
        let fileName = file.name;
        setFuneralSpeechFileName(fileName);
        setFuneralSpeech("");
    }

    function previewDocuments(event: any) {
        let file = event.target.files[0];
        let fileName = file.name;
        setDocumentsFileName(fileName);
        setDocuments("");
    }

    function fileNameFromUrl(url: string) {
        return url.substring(url.lastIndexOf('/') + 1);
    }

    function updateAshSpread() {
        // @ts-ignore
        let ashSpread = document.getElementById("ash_spread").value;
        setAshSpread(ashSpread);
        setFormChanged(true);
    }

    function updateBurialPlace() {
        // @ts-ignore
        let burialPlace = document.getElementById("burial_place").value;
        setBurialPlace(burialPlace);
        setFormChanged(true);
    }

    const funeralFormCheck = () => {
        let funeralFormCoffinGrave = document.getElementById('funeral_form_coffin_grave');
        let funeralFormCremationAndUrnGrave = document.getElementById('funeral_form_cremation_and_urn_grave');
        let funeralFormCremationAndNameMemorialGrove = document.getElementById('funeral_form_cremation_and_name_memorial_grove');
        let funeralFormCremationAndAnonymousMemorialGrove = document.getElementById('funeral_form_cremation_and_anonymous_memorial_grove');
        let funeralFormCremationAndAshScattering = document.getElementById('funeral_form_cremation_and_ash_scattering');
        let ashSpreadWrapper = document.getElementById('ash-spread-wrapper');
        let burialPlaceWrapper = document.getElementById('burial-place-wrapper');
        // @ts-ignore
        if (funeralFormCoffinGrave.checked || funeralFormCremationAndUrnGrave.checked || funeralFormCremationAndNameMemorialGrove.checked || funeralFormCremationAndAnonymousMemorialGrove.checked) {
            // @ts-ignore
            burialPlaceWrapper.classList.remove("d-none");
        } else {
            setBurialPlace("");
            // @ts-ignore
            if (!burialPlaceWrapper.classList.contains('d-none')) {
                // @ts-ignore
                burialPlaceWrapper.classList.add("d-none");
            }
        }
        // @ts-ignore
        if (funeralFormCremationAndAshScattering.checked) {
            // @ts-ignore
            ashSpreadWrapper.classList.remove("d-none");
        } else {
            setAshSpread("");
            // @ts-ignore
            if (!ashSpreadWrapper.classList.contains('d-none')) {
                // @ts-ignore
                ashSpreadWrapper.classList.add("d-none");
            }
        }
    }

    const selectApproveTerm = () => {
        setSuccessMessage("");
        setErrorMessage("");
        if (approveTerm) {
            setApproveTerm(false);
        } else {
            setApproveTerm(true);
        }
    };

    const saveAsDraft = () => {
        setPublished(false);
    }

    const saveAsPublished = () => {
        setPublished(true);
    }

    const enableUserTermCheckbox = (enable: boolean) => {
        if (enable) {
            // @ts-ignore
            document.getElementById("terms").disabled = false;
        }
    }

    useEffect(() => {
        getPreppForm();
    }, []);


    return (
        <div className="container">
            <Prompt when={formChanged} message={i18next.t('user.registration_form.form_has_changed')}/>
            {addReceiver &&
            <Receiver fromGreeting={true} addReceiverToggle={addReceiverToggle}
                      addOrRemoveReceiver={(receiverId: number) => addOrRemoveReceiver(receiverId)}/>
            }
            {apiFinished && !addReceiver &&
            <>
                <div className="m-4">
                    <div className="row">
                        <div className="col-12">
                            <h1>{i18next.t('prepp_form.title')}</h1>
                        </div>
                        <div className="col-12">
                            <span className="description">{i18next.t('prepp_form.description')}</span>
                        </div>
                    </div>
                </div>
                <form className="clearfix" id="prepp-form" method="post" encType="multipart/form-data"
                      onSubmit={registerPreppForm}>
                    <div className="content-wrapper">
                        <>
                            <div className={`${approveTerm ? 'd-none' : ''}`}>
                                <div className="row mb-3">
                                    <div className="form-group col-md-6">
                                        <label
                                            htmlFor="funeral_form">{i18next.t('prepp_form.fields.funeral_form.label')}</label>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="funeral_form"
                                                   id="funeral_form_coffin_grave" value="coffin_grave"
                                                   checked={funeralForm == 'coffin_grave'}
                                                   onChange={e => setFuneralForm(e.currentTarget.value)}
                                                   onClick={funeralFormCheck}/>
                                            <label className="form-check-label" htmlFor="funeral_form_coffin_grave">
                                                {i18next.t('prepp_form.fields.funeral_form.options.coffin_grave')}
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="funeral_form"
                                                   id="funeral_form_cremation_and_urn_grave"
                                                   value="cremation_and_urn_grave"
                                                   checked={funeralForm == 'cremation_and_urn_grave'}
                                                   onChange={e => setFuneralForm(e.currentTarget.value)}
                                                   onClick={funeralFormCheck}/>
                                            <label className="form-check-label"
                                                   htmlFor="funeral_form_cremation_and_urn_grave">
                                                {i18next.t('prepp_form.fields.funeral_form.options.cremation_and_urn_grave')}
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="funeral_form"
                                                   id="funeral_form_cremation_and_name_memorial_grove"
                                                   value="cremation_and_name_memorial_grove"
                                                   checked={funeralForm == 'cremation_and_name_memorial_grove'}
                                                   onChange={e => setFuneralForm(e.currentTarget.value)}
                                                   onClick={funeralFormCheck}/>
                                            <label className="form-check-label"
                                                   htmlFor="funeral_form_cremation_and_name_memorial_grove">
                                                {i18next.t('prepp_form.fields.funeral_form.options.cremation_and_name_memorial_grove')}
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="funeral_form"
                                                   id="funeral_form_cremation_and_anonymous_memorial_grove"
                                                   value="cremation_and_anonymous_memorial_grove"
                                                   checked={funeralForm == 'cremation_and_anonymous_memorial_grove'}
                                                   onChange={e => setFuneralForm(e.currentTarget.value)}
                                                   onClick={funeralFormCheck}/>
                                            <label className="form-check-label"
                                                   htmlFor="funeral_form_cremation_and_anonymous_memorial_grove">
                                                {i18next.t('prepp_form.fields.funeral_form.options.cremation_and_anonymous_memorial_grove')}
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="funeral_form"
                                                   id="funeral_form_cremation_and_ash_scattering"
                                                   value="cremation_and_ash_scattering"
                                                   checked={funeralForm == 'cremation_and_ash_scattering'}
                                                   onChange={e => setFuneralForm(e.currentTarget.value)}
                                                   onClick={funeralFormCheck}/>
                                            <label className="form-check-label"
                                                   htmlFor="funeral_form_cremation_and_ash_scattering">
                                                {i18next.t('prepp_form.fields.funeral_form.options.cremation_and_ash_scattering')}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="form-group col-md-6">
                                        <label
                                            htmlFor="funeral_ceremony">{i18next.t('prepp_form.fields.funeral_ceremony.label')}</label>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="funeral_ceremony"
                                                   id="funeral_ceremony_traditional_church_ceremony"
                                                   value="traditional_church_ceremony"
                                                   checked={funeralCeremony == 'traditional_church_ceremony'}
                                                   onChange={e => setFuneralCeremony(e.currentTarget.value)}/>
                                            <label className="form-check-label"
                                                   htmlFor="funeral_ceremony_traditional_church_ceremony">
                                                {i18next.t('prepp_form.fields.funeral_ceremony.options.traditional_church_ceremony')}
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="funeral_ceremony"
                                                   id="funeral_ceremony_humanistic_ceremony" value="humanistic_ceremony"
                                                   checked={funeralCeremony == 'humanistic_ceremony'}
                                                   onChange={e => setFuneralCeremony(e.currentTarget.value)}/>
                                            <label className="form-check-label"
                                                   htmlFor="funeral_ceremony_humanistic_ceremony">
                                                {i18next.t('prepp_form.fields.funeral_ceremony.options.humanistic_ceremony')}
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="funeral_ceremony"
                                                   id="funeral_ceremony_personal_ceremony" value="personal_ceremony"
                                                   checked={funeralCeremony == 'personal_ceremony'}
                                                   onChange={e => setFuneralCeremony(e.currentTarget.value)}/>
                                            <label className="form-check-label"
                                                   htmlFor="funeral_ceremony_personal_ceremony">
                                                {i18next.t('prepp_form.fields.funeral_ceremony.options.personal_ceremony')}
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="funeral_ceremony"
                                                   id="funeral_ceremony_no_ceremony" value="no_ceremony"
                                                   checked={funeralCeremony == 'no_ceremony'}
                                                   onChange={e => setFuneralCeremony(e.currentTarget.value)}/>
                                            <label className="form-check-label" htmlFor="funeral_ceremony_no_ceremony">
                                                {i18next.t('prepp_form.fields.funeral_ceremony.options.no_ceremony')}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row my-3" id="burial-place-wrapper">
                                    <div className="form-group col-md-6">
                                        <label
                                            htmlFor="burial_place">{i18next.t('prepp_form.fields.burial_place.label')}</label>
                                        <textarea name="burial_place" id="burial_place" className="form-control"
                                                  value={burialPlace}
                                                  onChange={updateBurialPlace}
                                        />
                                    </div>
                                </div>
                                <div className="row my-3" id="ash-spread-wrapper">
                                    <div className="form-group col-md-6">
                                        <label
                                            htmlFor="ash_spread">{i18next.t('prepp_form.fields.ash_spread.label')}</label>
                                        <textarea name="ash_spread" id="ash_spread" className="form-control"
                                                  value={ashSpread}
                                                  onChange={updateAshSpread}
                                        />
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="form-group col-md-6">
                                        <label
                                            htmlFor="funeral_agency">{i18next.t('prepp_form.fields.funeral_agency.label')}</label>
                                        <textarea name="funeral_agency" className="form-control"
                                                  defaultValue={funeralAgency}
                                                  onChange={formHasChanged}
                                        />
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="form-group col-md-6">
                                        <label
                                            htmlFor="funeral_location">{i18next.t('prepp_form.fields.funeral_location.label')}</label>
                                        <textarea name="funeral_location" className="form-control"
                                                  defaultValue={funeralLocation}
                                                  onChange={formHasChanged}
                                        />
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="form-group col-md-6">
                                        <label
                                            htmlFor="funeral_picture">{i18next.t('prepp_form.fields.funeral_picture.label')}</label>
                                        {funeralPicture != '' &&
                                        <div className="row">
                                            <div className="col-6">
                                    <span className="prepp-form-image-wrapper">
                                    <img src={funeralPicture}/>
                                    </span>
                                            </div>
                                        </div>}
                                        <input ref={inputFilePicture} className="d-none" type="file"
                                               id="funeral_picture"
                                               name="funeral_picture" accept="image/*" onChange={previewImage}/>
                                        <div className="col-12">
                                            <button onClick={onButtonPictureClick} type="button"
                                                    className="btn btn-primary mt-2">
                                                {i18next.t('prepp_form.button_choose_picture')}
                                            </button>
                                            {funeralPicture != '' && !funeralPicturePreview &&
                                            <button type="button" className="btn btn-primary mt-2"
                                                    onClick={() => deleteFile('funeral_picture')}>
                                                {i18next.t('prepp_form.button_delete_picture')}
                                            </button>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="form-group col-md-6">
                                        <label
                                            htmlFor="funeral_video">{i18next.t('prepp_form.fields.funeral_video.label')}</label>
                                        {funeralVideo != '' &&
                                        <div className="row">
                                            <div className="col-6">
                                                <video controls preload="metadata" id="funeral-video-wrapper"
                                                       className="prepp-form-video-wrapper">
                                                    <source type="video/mp4" src={funeralVideo} id="video-preview"/>
                                                </video>
                                            </div>
                                        </div>}
                                        <input ref={inputFileVideo} className="d-none" type="file" id="funeral_video"
                                               name="funeral_video"
                                               accept="video/mp4,video/x-m4v,video/*" onChange={previewVideo}/>
                                        <div className="col-12">
                                            <button onClick={onButtonVideoClick} type="button"
                                                    className="btn btn-primary mt-2">
                                                {i18next.t('prepp_form.button_choose_video')}
                                            </button>
                                            {funeralVideo != '' && !funeralVideoPreview &&
                                            <button type="button" className="btn btn-primary mt-2"
                                                    onClick={() => deleteFile('funeral_video')}>
                                                {i18next.t('prepp_form.button_delete_video')}
                                            </button>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="form-group col-md-6">
                                        <label
                                            htmlFor="funeral_speech">{i18next.t('prepp_form.fields.funeral_speech.label')}</label>
                                        <input ref={inputFileFuneralSpeech} className="d-none" type="file"
                                               id="funeral_speech"
                                               name="funeral_speech"
                                               accept=".doc,.docx,.odt,.txt,.rtf,.ppt,.pptx,.pdf"
                                               onChange={previewFuneralSpeech}/>
                                        <div className="col-12">
                                            <button onClick={onButtonFuneralSpeechClick} type="button"
                                                    className="btn btn-primary mt-2 mr-2">
                                                {i18next.t('prepp_form.button_choose_document')}
                                            </button>
                                            {funeralSpeech != '' &&
                                            <a href={funeralSpeech} target="_blank" className="btn btn-link pt-2">
                                                {fileNameFromUrl(funeralSpeech)}
                                            </a>}
                                            {funeralSpeech != '' &&
                                            <button type="button" className="btn btn-primary mt-2"
                                                    onClick={() => deleteFile('funeral_speech')}>
                                                {i18next.t('prepp_form.button_delete_document')}
                                            </button>}
                                            {funeralSpeechFileName}
                                        </div>
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="form-group col-md-6">
                                        <label
                                            htmlFor="burial_clothes">{i18next.t('prepp_form.fields.burial_clothes.label')}</label>
                                        <textarea name="burial_clothes" className="form-control"
                                                  defaultValue={burialClothes}
                                                  onChange={formHasChanged}
                                        />
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="form-group col-md-6">
                                        <label
                                            htmlFor="funeral_songs_and_poems">{i18next.t('prepp_form.fields.funeral_songs_and_poems.label')}</label>
                                        <textarea name="funeral_songs_and_poems" className="form-control"
                                                  defaultValue={funeralSongsAndPoems}
                                                  onChange={formHasChanged}
                                        />
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="form-group col-md-6">
                                        <label
                                            htmlFor="organization">{i18next.t('prepp_form.fields.organization.label')}</label>
                                        <textarea name="organization" className="form-control"
                                                  defaultValue={organization}
                                                  onChange={formHasChanged}
                                        />
                                    </div>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="1" id="donor"
                                           name="donor"
                                           checked={donor} onChange={e => setDonor(!donor)}/>
                                    <label className="form-check-label" htmlFor="donor">
                                        {i18next.t('prepp_form.fields.donor.label')}
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="1" id="donor_research"
                                           name="donor_research" checked={donorResearch}
                                           onChange={e => setDonorResearch(!donorResearch)}/>
                                    <label className="form-check-label" htmlFor="donor_research">
                                        {i18next.t('prepp_form.fields.donor_research.label')}
                                    </label>
                                </div>
                                <div className="row my-3">
                                    <div className="form-group col-md-6">
                                        <label
                                            htmlFor="codes">{i18next.t('prepp_form.fields.codes.label')}</label>
                                        <textarea name="codes" className="form-control"
                                                  defaultValue={codes}
                                                  onChange={formHasChanged}
                                        />
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="form-group col-md-6">
                                        <label
                                            htmlFor="finance_and_insurance">{i18next.t('prepp_form.fields.finance_and_insurance.label')}</label>
                                        <textarea name="finance_and_insurance" className="form-control"
                                                  defaultValue={financeAndInsurance}
                                                  onChange={formHasChanged}
                                        />
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="form-group col-md-6">
                                        <label
                                            htmlFor="documents">{i18next.t('prepp_form.fields.documents.label')}</label>
                                        <input ref={inputFileDocument} className="d-none" type="file" id="documents"
                                               name="documents"
                                               accept=".doc,.docx,.odt,.txt,.rtf,.xls,.xlsx,.ppt,.pptx,.pdf"
                                               onChange={previewDocuments}/>
                                        <div className="col-12">
                                            <button onClick={onButtonDocumentClick} type="button"
                                                    className="btn btn-primary mt-2 mr-2">
                                                {i18next.t('prepp_form.button_choose_document')}
                                            </button>
                                            {documents != '' &&
                                            <a href={documents} target="_blank" className="btn btn-link pt-0">
                                                {fileNameFromUrl(documents)}
                                            </a>}
                                            {documents != '' &&
                                            <button type="button" className="btn btn-primary mt-2"
                                                    onClick={() => deleteFile('documents')}>
                                                {i18next.t('prepp_form.button_delete_document')}
                                            </button>}
                                            {documentsFileName}
                                        </div>
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="form-group col-md-6">
                                        <label
                                            htmlFor="pets">{i18next.t('prepp_form.fields.pets.label')}</label>
                                        <textarea name="pets" className="form-control"
                                                  defaultValue={pets}
                                                  onChange={formHasChanged}
                                        />
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="form-group col-md-6">
                                        <label
                                            htmlFor="other_wishes">{i18next.t('prepp_form.fields.other_wishes.label')}</label>
                                        <textarea name="other_wishes" className="form-control"
                                                  defaultValue={otherWishes}
                                                  onChange={formHasChanged}
                                        />
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="form-group col-md-6">
                                        <label
                                            htmlFor="written_greeting">{i18next.t('prepp_form.fields.written_greeting.label')}</label>
                                        <textarea name="written_greeting" className="form-control"
                                                  defaultValue={writtenGreeting}
                                                  onChange={formHasChanged}
                                        />
                                    </div>
                                </div>

                                <ReceiverList receiverList={receiverList} fromGreeting={true}
                                              addReceiverToggle={addReceiverToggle}
                                              addOrRemoveReceiver={(receiverId: number) => addOrRemoveReceiver(receiverId)}/>

                                <div className="form-check">
                                    <input className="form-check-input d-none" type="checkbox" value="1" id="published"
                                           name="published" checked={published}
                                           onChange={e => setPublished(!published)}/>
                                </div>
                                {published &&
                                <div className="row my-3">
                                    <div className="form-group col-md-12">
                                        {i18next.t('prepp_form.message_published')}
                                    </div>
                                </div>}
                                {!published &&
                                <div className="row my-3">
                                    <div className="form-group col-md-12">
                                        {i18next.t('prepp_form.message_unpublished')}
                                    </div>
                                </div>}
                                <div className="row my-3">
                                    <div className="form-group col-md-12">
                                        {i18next.t('prepp_form.message')}
                                    </div>
                                </div>
                            </div>
                        </>
                        {approveTerm &&
                        <div className="row mb-3 p-2">
                            <div className="col-12">
                                <p>Hei, du skal nå publisere ditt preppskjema.</p>
                                <p>Kryss av for at du har lest våre vilkår og trykk Publiser igjen.</p>
                            </div>
                            <div className="col-12">
                                <div className="form-check">
                                    <input className="form-check-input " type="checkbox" id="terms" name="terms"
                                           disabled
                                           required/>
                                    <label className="form-check-label">
                                        <button type="button" className="btn btn-link pt-0" data-bs-toggle="modal"
                                                data-bs-target="#user-term-modal">{i18next.t('user.registration_form.fields.terms.label')}</button>
                                    </label>
                                </div>
                            </div>
                            <UserTermsModal
                                enableUserTermCheckbox={(enable: boolean) => enableUserTermCheckbox(enable)}/>
                        </div>}
                    </div>
                    {errorMessage !== "" &&
                    <div className="alert alert-danger" role="alert">
                        {errorMessage}
                    </div>}
                    {successMessage !== "" &&
                    <div className="alert alert-success" role="alert">
                        {successMessage}
                    </div>}
                    {!approveTerm && !published &&
                    <button onClick={selectApproveTerm} type="button" id="prepp-form-term-button"
                            className="btn btn-primary float-end">
                        {i18next.t('prepp_form.button_publish')}
                    </button>}
                    {!approveTerm &&
                    <>
                        <button type="submit" id="prepp-form-registration-button"
                                className="btn btn-primary float-end me-2">
                            {i18next.t('prepp_form.button_register')}
                        </button>
                        <button onClick={saveAsDraft} type="submit" id="prepp-form-unpublish-button"
                                className={`btn btn-primary float-end me-2 ${published ? '' : 'd-none'}`}>
                            {i18next.t('prepp_form.button_unpublish')}
                        </button>
                    </>}
                    {approveTerm &&
                    <>
                        <button onClick={saveAsPublished} type="submit" id="prepp-form-registration-form-button"
                                className="btn btn-primary float-end">
                            {i18next.t('prepp_form.button_publish')}
                        </button>
                        <button onClick={selectApproveTerm} type="button" id="prepp-form-term-button"
                                className="btn btn-primary float-end me-2">
                            {i18next.t('prepp_form.button_edit')}
                        </button>
                    </>
                    }
                </form>
            </>}
        </div>
    );
}

export default PreppForm;