import React, {useEffect, useState} from 'react';
import i18next from '../../languages/no/translation';
import {Prompt, useHistory} from "react-router-dom";
import axios from "axios";
import {useAppSelector} from "../../store/hooks";
import {selectJwt, selectUserId} from "../../store/slices/authenticationSlice";

function Receiver(props: any) {

    let register_receiver_ajax_lock = false;
    let delete_receiver_ajax_lock = false;

    const receiver_id = props.match && props.match.params && props.match.params.receiver_id ? props.match.params.receiver_id : null;
    const authentication = useAppSelector(selectJwt);
    const user = useAppSelector(selectUserId);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [apiUrl, setApiUrl] = useState("/user/" + user.user_id + "/receiver/new");
    let [receiver, setReceiver] = useState<any>(null);
    let [greetings, setGreetings] = useState<any[]>([]);
    const [formChanged, setFormChanged] = useState(false);
    const [isReceiverMinor, setIsReceiverMinor] = useState(false);
    const history = useHistory();

    const toggleMinor = () => {
        if (isReceiverMinor) {
            setIsReceiverMinor(false);
        } else {
            setIsReceiverMinor(true);
        }
    }

    const registerReceiver = (e: any) => {
        e.preventDefault();
        if (!register_receiver_ajax_lock) {
            register_receiver_ajax_lock = true;
            let receiverRegistrationForm = document.getElementById("receiver-registration-form") as HTMLFormElement;
            let formData = new FormData(receiverRegistrationForm);
            const receiverObject = {} as any;
            formData.forEach((value, key) => (receiverObject[key] = value));
            if (!isReceiverMinor && receiverObject.date_of_birth != undefined) {
                delete (receiverObject.date_of_birth);
            }
            if (props.isMinor) {
                receiverObject.type = 1;
            }
            setErrorMessage("");
            setFormChanged(false);
            // @ts-ignore
            axios({
                url: apiUrl,
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": authentication.jwt
                },
                data: receiverObject
            })
                .then(function (response: any) {
                    const obj = response.data;
                    if (obj.receiver_id !== undefined) {
                        if (props.fromGreeting) {
                            if(props.isMinor){
                                props.addOrRemoveReceiverGuardian(props.minorReceiverId, obj.receiver_id);
                            }else{
                                props.addOrRemoveReceiver(obj.receiver_id);
                            }
                            props.addReceiverToggle();
                        } else {
                            if (receiver) {
                                setSuccessMessage(i18next.t('receiver.registration_form.success_message_update'));
                            }
                            if (!receiver) {
                                setSuccessMessage(i18next.t('receiver.registration_form.success_message_register'));
                            }
                            setTimeout(function () {
                                history.push("/receivers");
                            }, 5000);
                        }
                    }
                    register_receiver_ajax_lock = false;
                })
                .catch(function (error: any) {
                    setErrorMessage(error.response.data.error);
                    register_receiver_ajax_lock = false;
                });
        }
    }

    function getReceiver() {
        if (!isNaN(receiver_id) && receiver_id !== null) {
            // @ts-ignore
            axios({
                url: "/user/" + user.user_id + "/receiver/" + receiver_id,
                method: "get",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": authentication.jwt
                }
            })
                .then(function (response: any) {
                    const obj = response.data;
                    if (obj.minor == true) {
                        setIsReceiverMinor(true);
                    }
                    setReceiver(obj);
                    setApiUrl("/user/" + user.user_id + "/receiver/" + receiver_id + "/edit");
                })
                .catch(function (error: any) {

                });
        }
    }

    const deleteReceiver = (e: any) => {
        e.preventDefault();
        if (!delete_receiver_ajax_lock) {
            delete_receiver_ajax_lock = true;
            setErrorMessage("");
            if (!isNaN(receiver_id) && receiver_id !== null) {
                if (!window.confirm(i18next.t('receiver.receiver_list.delete_confirm_message'))) {
                    return false;
                }
                // @ts-ignore
                axios({
                    url: "/user/" + user.user_id + "/receiver/" + receiver_id + "/delete",
                    method: "get",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": authentication.jwt
                    }
                })
                    .then(function (response: any) {
                        const obj = response.data;
                        if (obj.receiver_id !== undefined) {
                            setSuccessMessage(i18next.t('receiver.registration_form.success_message_delete'));
                            setTimeout(function () {
                                history.push("/receivers");
                            }, 5000);
                        }
                        delete_receiver_ajax_lock = false;
                    })
                    .catch(function (error: any) {
                        setErrorMessage(error.response.data.error);
                        setGreetings(error.response.data.greetings);
                        delete_receiver_ajax_lock = false;
                    });
            }
        }
    }

    useEffect(() => {
        getReceiver();
    }, []);

    return (
        <div className="container">
            <Prompt when={formChanged} message={i18next.t('user.registration_form.form_has_changed')}/>
            <form className="clearfix" id="receiver-registration-form" method="post" onSubmit={registerReceiver}>
                <div className="content-wrapper">
                    <div className="row my-3">
                        <div className="form-group col-md-6">
                            <label
                                htmlFor="first_name">{i18next.t('receiver.registration_form.fields.first_name.label')}</label>
                            <input onChange={() => setFormChanged(true)} type="text" className="form-control"
                                   name="first_name"
                                   defaultValue={receiver && receiver.first_name}
                                   placeholder={i18next.t('receiver.registration_form.fields.first_name.placeholder')}
                                   required/>
                        </div>
                        <div className="form-group col-md-6">
                            <label
                                htmlFor="last_name">{i18next.t('receiver.registration_form.fields.last_name.label')}</label>
                            <input onChange={() => setFormChanged(true)} type="text" className="form-control"
                                   name="last_name"
                                   defaultValue={receiver && receiver.last_name}
                                   placeholder={i18next.t('receiver.registration_form.fields.last_name.placeholder')}
                                   required/>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="form-group col-md-12">
                            <label
                                htmlFor="email">{i18next.t('receiver.registration_form.fields.email.label')}</label>
                            <input onChange={() => setFormChanged(true)} type="email" className="form-control"
                                   name="email"
                                   defaultValue={receiver && receiver.email}
                                   placeholder={i18next.t('receiver.registration_form.fields.email.placeholder')}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="form-group col-md-6">
                            <label
                                htmlFor="phone_country_code">{i18next.t('receiver.registration_form.fields.phone_country_code.label')}</label>
                            <input onChange={() => setFormChanged(true)} type="text" className="form-control"
                                   name="phone_country_code"
                                   defaultValue={receiver && receiver.phone_country_code}
                                   placeholder={i18next.t('receiver.registration_form.fields.phone_country_code.placeholder')}/>
                        </div>
                        <div className="form-group col-md-6">
                            <label
                                htmlFor="phone_number">{i18next.t('receiver.registration_form.fields.phone_number.label')}</label>
                            <input onChange={() => setFormChanged(true)} type="text" className="form-control"
                                   name="phone_number"
                                   defaultValue={receiver && receiver.phone_number}
                                   placeholder={i18next.t('receiver.registration_form.fields.phone_number.placeholder')}/>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="form-group col-md-12">
                            <label
                                htmlFor="address">{i18next.t('receiver.registration_form.fields.address.label')}</label>
                            <input onChange={() => setFormChanged(true)} type="text" className="form-control"
                                   name="address"
                                   defaultValue={receiver && receiver.address}
                                   placeholder={i18next.t('receiver.registration_form.fields.address.placeholder')}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="form-group col-md-12">
                            <label
                                htmlFor="address_line_2">{i18next.t('receiver.registration_form.fields.address.label')}</label>
                            <input onChange={() => setFormChanged(true)} type="text" className="form-control"
                                   name="address_line_2"
                                   defaultValue={receiver && receiver.address_line_2}
                                   placeholder={i18next.t('receiver.registration_form.fields.address.placeholder')}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="form-group col-md-6">
                            <label htmlFor="zip">{i18next.t('receiver.registration_form.fields.zip.label')}</label>
                            <input onChange={() => setFormChanged(true)} type="text" className="form-control" name="zip"
                                   defaultValue={receiver && receiver.zip}
                                   placeholder={i18next.t('receiver.registration_form.fields.zip.placeholder')}/>
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="city">{i18next.t('receiver.registration_form.fields.city.label')}</label>
                            <input onChange={() => setFormChanged(true)} type="text" className="form-control"
                                   name="city"
                                   defaultValue={receiver && receiver.city}
                                   placeholder={i18next.t('receiver.registration_form.fields.city.placeholder')}/>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="form-group col-md-6">
                            <label
                                htmlFor="country">{i18next.t('receiver.registration_form.fields.country.label')}</label>
                            <input onChange={() => setFormChanged(true)} type="text" className="form-control"
                                   name="country"
                                   defaultValue={receiver && receiver.country}
                                   placeholder={i18next.t('receiver.registration_form.fields.country.placeholder')}/>
                        </div>
                    </div>
                    {!props.isMinor &&
                    <div className="row mb-3">
                        <div className="form-group col-md-6">
                            <div className="form-check">
                                <input onChange={toggleMinor} className="form-check-input" type="checkbox" id="minor"
                                       checked={isReceiverMinor}/>
                                <label className="form-check-label" htmlFor="minor">
                                    {i18next.t('receiver.registration_form.fields.minor.label')}
                                </label>
                            </div>
                        </div>
                        {isReceiverMinor &&
                        <div className="form-group col-md-6">
                            <label
                                htmlFor="date_of_birth">{i18next.t('receiver.registration_form.fields.date_of_birth.label')}</label>
                            <input onChange={() => setFormChanged(true)} type="date" className="form-control"
                                   id="date_of_birth"
                                   name="date_of_birth"
                                   defaultValue={receiver && receiver.date_of_birth}
                                   placeholder={i18next.t('receiver.registration_form.fields.date_of_birth.placeholder')}/>
                        </div>}
                    </div>}
                </div>
                {errorMessage !== "" &&
                <div className="alert alert-danger" role="alert">
                    {errorMessage}
                </div>}
                {greetings.length !== 0 &&
                <div className="mb-4">
                    <ul className="list-group" id="receiver-list">
                        {greetings.map((greeting) => (
                            <li className="list-group-item list-group-item-action" key={greeting.greeting_id}>
            <span className="float-start">
                {greeting.title}
            </span>
                            </li>))}

                    </ul>
                </div>
                }
                {successMessage !== "" &&
                <div className="alert alert-success" role="alert">
                    {successMessage}
                </div>}
                {successMessage === "" &&
                <button type="submit" id="receiver-registration-form-button" className="btn btn-primary float-end">
                    {receiver === null && i18next.t('receiver.registration_form.button_register')}
                    {receiver && i18next.t('receiver.registration_form.button_update')}
                </button>}
                {receiver && successMessage === "" &&
                <button onClick={deleteReceiver} type="button" id="receiver-delete-button"
                        className="btn btn-danger float-start" data-receiver-id={receiver.receiver_id}>
                    {i18next.t('receiver.registration_form.button_delete')}
                </button>}
                {props.fromGreeting &&
                <button onClick={props.addReceiverToggle} type="button" id="receiver-cancel-button"
                        className="btn btn-primary float-start">
                    {i18next.t('receiver.registration_form.button_cancel')}
                </button>}

            </form>
        </div>
    );
}

export default Receiver;