import React from 'react';
import 'material-icons/iconfont/material-icons.scss';

function GreetingContentBlockImagePreview(props: any) {

    return (
        <div className="m-4">
            <div className="row">
                <div className="col-12 mb-0">
                    <span className="greeting-content-block-wrapper">
                        <img src={props.greetingContentBlock.image}
                             alt=""/>
                    </span>
                </div>
                <div className="col-12 mt-0">
                    <span className="greeting-content-block-wrapper">
                        {props.greetingContentBlock.body}
                        </span>
            </div>
            </div>
        </div>
    );
}

export default GreetingContentBlockImagePreview;