import React, {useEffect, useState} from 'react';
import axios from "axios";

function Privacy() {

    const [privacy, setPrivacy] = useState("");

    function getPrivacy() {
        // @ts-ignore
        axios({
            url: "/privacy",
            method: "get",
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(function (response: any) {
                const obj = response.data;
                setPrivacy(obj.privacy);
            })
            .catch(function (error: any) {

            });
    }


    useEffect(() => {
        getPrivacy();
    }, []);

    return (
        <div className="container">
            <div className="content-wrapper">
                <div className="row my-3">
                    <div className="col-12">
                        {privacy &&
                        privacy}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Privacy;