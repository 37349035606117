import React, {useEffect, useState} from 'react';
import i18next from "../../languages/no/translation";
import {Link} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {selectJwt, selectUserId} from "../../store/slices/authenticationSlice";
import axios from "axios";
import GreetingListDetail from "./GreetingListDetail";
import {setBackgroundImage} from "../../store/slices/styleSlice";

function GreetingList() {

    const authentication = useAppSelector(selectJwt);
    const user = useAppSelector(selectUserId);

    let [greetings, setGreetings] = useState<any[]>([]);
    let [apiFinished, setApiFinished] = useState(false);

    function getGreetings() {
        // @ts-ignore
        axios({
            url: "/user/" + user.user_id + "/greetings",
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": authentication.jwt
            }
        })
            .then(function (response: any) {
                const obj = response.data;
                setGreetings(obj.greetings);
                setApiFinished(true);
            })
            .catch(function (error: any) {

            });
    }

    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(setBackgroundImage('/images/clouds.jpg'));
        getGreetings()
    }, []);

    return (
        <div className="container">
            {apiFinished &&
            <div className="my-4">
                <div className="row">
                    <div className="col-12">
                        <h1>{i18next.t('greeting.greeting_list.title')}</h1>
                    </div>
                    <div className="col-12">
                        <Link to="/greeting"
                              className="btn btn-primary">{i18next.t('greeting.greeting_list.add_new_greeting_button')}</Link>
                        <Link to="/greeting?video=1"
                              className="btn btn-primary ms-3">{i18next.t('greeting.greeting_list.add_new_video_greeting_button')}</Link>
                    </div>
                </div>
                <div>
                    <ul className="list-group my-3" id="receiver-list">
                        {greetings && greetings.length !== 0 && greetings.map((greeting, index) => (
                            <GreetingListDetail greeting={greeting} key={index} />
                        ))}
                    </ul>
                    {greetings.length === 0 &&
                    <div>{i18next.t('greeting.greeting_list.no_greetings')}</div>}
                </div>
            </div>
            }
        </div>
    );
}

export default GreetingList;