import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import {setBackgroundImage} from "../../store/slices/styleSlice";
import {useAppDispatch} from "../../store/hooks";

function Frontpage() {

    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(setBackgroundImage('/images/man.jpg'));
    }, []);

    return (
        <div className="row">
            <div className="col-12">
                <img className="frontpage-logo" src="/logo.svg" alt="Logo"/>
            </div>
            <div className="col-12">
                <Link to="/register" id="frontpage-button" className="btn btn-primary btn-primary-light">Si det
                    idag</Link>
            </div>
        </div>
    );
}

export default Frontpage;