import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';

export interface StyleState {
    background_image: string | null;
}

const initialState: StyleState = {
    background_image: '/images/clouds.jpg'
};

export const styleSlice = createSlice({
    name: 'style',
    initialState,
    reducers: {
        setBackgroundImage: (state, action: PayloadAction<string>) => {
            state.background_image = action.payload;
        }
    }
});

export const {setBackgroundImage} = styleSlice.actions;
export const selectBackgroundImage = (state: RootState) => state.background_image;

export default styleSlice.reducer;
